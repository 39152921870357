import {Component, EventEmitter, Inject} from '@angular/core';
import {humanizeBytes, UploaderOptions, UploadFile, UploadInput, UploadOutput, UploadStatus} from 'ngx-uploader';
import {APP_CONFIG, IAppConfig} from '../../../app.config';
import {LocalStorageKeys} from '../../../models/local-storage-keys';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';

export interface AttachmentDialogParameters {
  public?: boolean;
  allowedContentTypes?: string[];
  description?: string;
}
@Component({
  selector:    'app-attachment-dialog',
  templateUrl: './attachment-dialog.component.html',
  styleUrls:   ['./attachment-dialog.component.scss']
})
export class AttachmentDialogComponent {

  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  options: UploaderOptions;

  results: UploadOutput[] = [];

  constructor(@Inject(APP_CONFIG) private config: IAppConfig,
              public dialogRef: MatDialogRef<AttachmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AttachmentDialogParameters) {
    this.options = { concurrency: 1, allowedContentTypes: this.data.allowedContentTypes };
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  onUploadOutput(output: UploadOutput): void {

    this.files = this.files.filter(file => file.progress.status !== UploadStatus.Done);

    if (output.type === 'allAddedToQueue') {
      if (this.data.public) {
        this.uploadToCloudinaryUpload();
      } else {
        this.startUpload();
      }
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
      this.files.push(output.file);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
      console.log(output.file.name + ' rejected');
    } else if (output.type === 'done') {
      this.results = this.results.concat(output.file.response);
      if (this.files.length === 0) {
        this.dialogRef.close(this.results);
      }
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type:    'uploadAll',
      url:     `${this.config.apiUrl}/attachment`,
      headers: {'Authorization': localStorage.getItem(LocalStorageKeys.API_TOKEN)},
      method:  'POST',
      data:    {foo: 'bar'}
    };

    this.uploadInput.emit(event);
  }

  uploadToCloudinaryUpload() {
    const data: UploadInput = {
      type: 'uploadAll',
      url: `${environment.cloudinaryURL}/${environment.cloudinaryCloudName}/upload`,
      method: 'POST',
      data: {
        upload_preset: environment.cloudinaryUploadPreset,
        tags: 'ablum',
        context: 'photo=' + ('title' || ''),
      },
    }
    this.uploadInput.emit(data);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({
      type: 'cancel',
      id:   id
    });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({
      type: 'remove',
      id:   id
    });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({type: 'removeAll'});
  }

}
