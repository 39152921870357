import {createAction, props} from '@ngrx/store';
import {AccountDetails} from '@automata/models/account-details';
import {AccountDetailsForm} from '@automata/interfaces/account/account-details-form';
import {ApiKey} from '@automata/models/api-key';
import {AccountApiKeys} from '@automata/interfaces/account/account-api-keys';
import {ApiKeyDelete} from '@automata/interfaces/account/api-key-delete';
import {AddApiKeyPayload} from '@automata/interfaces/account/add-api-key-payload';

export const LoadAccount = createAction('[Account] Load Account');
export const LoadAccountComplete = createAction('[Account] Load Account Complete', props<{account: AccountDetails}>());

export const SaveAccount = createAction('[Account] Save Start', props<{account: AccountDetails, form: AccountDetailsForm}>());
export const SaveAccountComplete = createAction('[Account] Save Complete', props<{account: AccountDetails}>());

export const LoadApiKeys = createAction('[Account] Load Api Keys Start');
export const LoadApiKeysComplete = createAction('[Account] Load Api Keys Complete', props<{keys: AccountApiKeys}>());

export const DeleteApiKey = createAction('[Account] Delete Api Key Start', props<{key: ApiKey}>());
export const DeleteApiKeyComplete = createAction('[Account] Delete Api Key Complete', props<{key: ApiKeyDelete}>());

export const AddApiKey = createAction('[Account] Add Api Key', props<{key: AddApiKeyPayload}>());

export const SkipTriggers = createAction('[Account] Skip Triggers Start', props<{id: string}>());
export const SkipTriggersComplete = createAction('[Account] Skip Triggers Complete');

export const GetSkipTriggers = createAction('[Account] GetSkip Triggers Start');
export const GetSkipTriggersComplete = createAction('[Account] GetSkip Triggers Complete', props<{isSkipped: boolean}>());

export const UnSkipTriggers = createAction('[Account] UnSkip Triggers Start', props<{id: string}>());
export const UnSkipTriggersComplete = createAction('[Account] UnSkip Triggers Complete');
