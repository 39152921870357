<form [formGroup]="form">

  <div class="row">
    <div class="col-sm-6">

      <div class="row" *ngIf="templates" [ngClass]="{hidden: !isEditing()}">

        <div class="col-sm-8">
          <app-select-search [items]="templates"
                             [ctrl]="form.get('id')"
                             [matTooltip]="'tooltips.selectNewTemplateOfSameType' | translate"
                             matTooltipPosition="above"
                             matTooltipClass="text-center"
                             [multiple]="false"
                             [placeholder]="'formControls.switchTemplate' | translate"></app-select-search>
        </div>

        <div class="col-sm-4 disabledType" *ngIf="isEditing()">
          <mat-form-field>
            <mat-select [placeholder]="'formControls.type' | translate" formControlName="type">
              <mat-option [value]="types.Email">Email</mat-option>
              <mat-option [value]="types.PushNotification">SMS</mat-option>
              <mat-option [value]="types.Payload">Payload</mat-option>
              <mat-option *ngIf="hasAutoReviews" [value]="types.Review">Review</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-8" *ngIf="!isEditing()">
          <mat-form-field [matTooltip]="'tooltips.enterNameForTemplate' | translate"
                          matTooltipPosition="above"
                          matTooltipClass="text-center">
            <input matInput
                   [placeholder]="'formControls.name' | translate"
                   formControlName="name"
                   [required]="true">
            <mat-error *ngIf="form.get('name').hasError('required')">
              <span [innerHtml]="'validations.nameRequired' | translate"></span>
            </mat-error>
            <mat-error *ngIf="form.get('name').hasError('pattern')">
              <span [innerHtml]="'validations.nameNoSpecialNorMinus' | translate"></span>
            </mat-error>
            <mat-error *ngIf="form.get('name').hasError('maxlength')">
              <span [innerHtml]="'validations.nameAtMost50' | translate"></span>
            </mat-error>
            <mat-hint align="end">{{form.get('name').value.length || 0}}/50</mat-hint>
          </mat-form-field>
        </div>

        <div *ngIf="!isEditing()" class="col-sm-4">
          <mat-form-field>
            <mat-select [placeholder]="'formControls.type' | translate"
                        [matTooltip]="'tooltips.selectTemplateType' | translate"
                        matTooltipPosition="above"
                        matTooltipClass="text-center"
                        formControlName="type">
              <mat-option [value]="types.Email">Email</mat-option>
              <mat-option [value]="types.PushNotification">SMS</mat-option>
              <mat-option [value]="types.Payload">Payload</mat-option>
              <mat-option *ngIf="hasAutoReviews" [value]="types.Review">Review</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">

        <div
          [ngClass]="{'col-sm-8': form.get('type').value === types.Payload, 'col-sm-12': form.get('type').value !== types.Payload}">
          <mat-form-field [matTooltip]="'tooltips.enterTemplateDescription' | translate"
                          matTooltipPosition="above"
                          matTooltipClass="text-center">
            <input matInput
                   [placeholder]="'formControls.description' | translate"
                   formControlName="description">
            <mat-error *ngIf="form.get('description').hasError('maxlength')">
              <span [innerHtml]="'validations.descriptionAtMost100' | translate"></span>
            </mat-error>
            <mat-hint align="end">{{form.get('description').value.length || 0}}/100</mat-hint>
          </mat-form-field>
        </div>

        <div *ngIf="form.get('type').value === types.Payload" class="col-sm-4">
          <mat-form-field>
            <mat-select [placeholder]="'formControls.method' | translate" formControlName="method">
              <mat-option [value]="methods.GET">GET</mat-option>
              <mat-option [value]="methods.POST">POST</mat-option>
              <mat-option [value]="methods.PUT">PUT</mat-option>
              <mat-option [value]="methods.PATCH">PATCH</mat-option>
              <mat-option [value]="methods.DELETE">DELETE</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>

      <div *ngIf="form.get('type').value === types.Email" class="row">
        <div class="col-sm-12">
          <mat-form-field [matTooltip]="'tooltips.enterTemplateSubject' | translate"
                          matTooltipPosition="above"
                          matTooltipClass="text-center">
            <input matInput
                   [placeholder]="'formControls.subject' | translate"
                   formControlName="subject">
            <mat-error *ngIf="form.get('subject').hasError('maxlength')">
              <span [innerHtml]="'validations.subjectAtMost75' | translate"></span>
            </mat-error>
            <mat-hint align="end">{{form.get('subject').value.length || 0}}/75</mat-hint>
          </mat-form-field>
        </div>
      </div>

    </div>

    <div class="col-sm-6">

      <div class="row">
        <div *ngIf="form.get('type').value === types.Payload" class="col-sm-12">
          <app-http-headers-table [headers]="template?.headers || []"
                                  [payloadControl]="payloadControl"
                                  (change)="onHeadersChange($event)"></app-http-headers-table>
        </div>
        <div
          *ngIf="form.get('type').value === types.PushNotification || form.get('type').value === types.Email">
          <app-translations-table [template]="template"
                                  [content]="form.get('content').get('body')"
                                  [subject]="form.get('subject')"
                                  [translateControl]="translateControl"
                                  [type]="form.get('type').value"
                                  [languages]="template?.languages || []"
                                  (change)="onTranslationsChange($event)"></app-translations-table>
        </div>
      </div>

    </div>
  </div>

  <app-template-editor [content]="template?.body"
                       [subject]="form.get('subject')"
                       [type]="form.get('type')"
                       [form]="form.get('content')"></app-template-editor>

</form>
