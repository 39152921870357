<div>
  <h2 mat-dialog-title>
    Email Settings
  </h2>
  <button (click)="close()" class="close" aria-label="Close" type="button">
    <span class="far fa-times"></span>
  </button>
</div>

<mat-dialog-content>
  <form [formGroup]="form">
    <div class="row center-row">
      <div class="col-sm-12">
        <p>Edit the field(s) below, to modify the email settings for this particular trigger. Email settings for other triggers will be unaffected.</p>
      </div>

      <div class="col-sm-offset-2 col-sm-8">
        <mat-form-field>
          <input matInput placeholder="Show this name on emails:" formControlName="name"
                 [required]="true">
          <mat-error *ngIf="form.get('name').hasError('required')">
            <span [innerHtml]="'validations.nameRequired' | translate"></span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>

<div class="row bottom-row">
  <div class="col-sm-12 text-right">
    <button (click)="close()" class="btn btn-default left-icon mr10 text-uppercase" type="button">
      <i class="far fa-check-square"></i> {{'buttons.cancel' | translate}}
    </button>
    <button (click)="save(form)" [disabled]="form.invalid" class="btn btn-new left-icon text-uppercase" type="button">
      <i class="far fa-save"></i> {{'buttons.save' | translate}}
    </button>
  </div>
</div>
