import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SaveForm} from '@automata/decorators/save.decorator';

@Component({
  selector:    'app-email-from-settings-dialog',
  templateUrl: './email-from-settings-dialog.component.html',
  styleUrls:   ['./email-from-settings-dialog.component.scss']
})
export class EmailFromSettingsDialogComponent implements OnInit {

  form = this.fb.group({
    name: ['', [Validators.required]]
  })

  constructor(public dialogRef: MatDialogRef<EmailFromSettingsDialogComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: {name: string}) {
  }

  ngOnInit() {
    this.form.patchValue({name: this.data.name});
  }

  close() {
    this.dialogRef.close();
  }

  @SaveForm()
  save(form: FormGroup) {
    const {name} = form.getRawValue();
    this.dialogRef.close(name);
  }
}
