export const environment = {
  sentryDns: 'https://5a9b938ff3c244ef9ed0935d07113c29@sentry.io/1249660',
  environment: 'staging',
  wootricToken: 'NPS-a11df413',
  production: true,
  apiUrl: 'https://stage.api.tokeet.com',
  signatureApiUrl: 'https://dev.api.usesignature.com',
  samplesUrl: 'https://stage.app.useautomata.com/assets',
  iftttUrl: 'https://dev.ifttt.tokeet.com',
  intercomAppId: 'ziijopdr',
  features: {
    hometogo: true,
    autoReviews: true,
  },

  cloudinaryURL: 'https://api.cloudinary.com/v1_1',
  cloudinaryCloudName: 'tokeet',
  cloudinaryUploadPreset: 'isnhfkd5',
};
