import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {EmailFromSettingsDialogComponent} from '@automata/components/trigger/trigger-dialog/email-from-settings.dialog/email-from-settings-dialog.component';

@Injectable()
export class EmailFromSettingsDialogService {

  constructor(private dialog: MatDialog) {
  }

  public openSettings(name: string) {

    const defaultConfig: MatDialogConfig = {
      width:      '540px',
      height:     `280px`,
      data:       {name},
    };

    return this.dialog.open(EmailFromSettingsDialogComponent, defaultConfig).afterClosed()
  }
}
