import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TemplateType} from '../../../enums/template-type';
import {Template} from '../../../models/template';
import {select, Store} from '@ngrx/store';
import {Validations} from '../../../models/validations';
import * as fromRoot from '../../../reducers';
import {TemplateForm} from '../../../interfaces/template/template-form';
import {isSomething} from '../../../utility/functions/is-something';
import * as R from 'ramda';
import {
  distinctUntilChanged,
  filter,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import {PayloadMethod} from '../../../enums/payload-method';
import {PayloadHttpHeader} from '../../../models/payload-http-header';
import {TemplateTranslation} from '../../../models/template-translation';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.scss'],
})
export class TemplateFormComponent
  extends OnDestroyMixin
  implements OnInit, OnChanges {
  @Input() templateId: string;
  @Input() type?: TemplateType;

  @Output() change = new EventEmitter<TemplateForm>();
  @Output() valid = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<boolean>();
  @Input() translateControl: EventEmitter<boolean>;
  @Input() payloadControl: EventEmitter<boolean>;

  template: Template;
  templates: Template[];
  types = TemplateType;
  methods = PayloadMethod;

  form: FormGroup;

  hasAutoReviews = environment.features.autoReviews;

  constructor(private fb: FormBuilder, private store: Store<fromRoot.State>) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    const templateId = R.path(['templateId', 'currentValue'], changes);
    if (templateId && this.form) {
      this.form.patchValue({id: templateId});
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      id: [this.templateId || ''],
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(
            /^([0-9a-zа-яàáâãäåąæçćèéêëęœìíïîłńðòóôõöøśùúûñüýÿżźßÞďđ\s\-])+$/i
          ),
          Validators.pattern(/^((?!\s\-\s).)*$/i),
        ],
      ],
      description: ['', [Validators.maxLength(100)]],
      type: [this.type || TemplateType.Email],
      method: [PayloadMethod.POST],
      headers: [''],
      subject: ['', [Validators.maxLength(75)]],
      languages: [''],
      content: this.fb.group({
        body: ['', [Validators.required]],
      }),
    });

    this.form.valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe((values) => {
        this.onChange(values);
      });

    this.form
      .get('type')
      .valueChanges.pipe(
        startWith(this.type || TemplateType.Email),
        distinctUntilChanged(),
        tap((type) => this.changeValidators(type)),
        switchMap((type) =>
          this.store.pipe(select(fromRoot.selectTemplatesByType(type)))
        ),
        untilComponentDestroyed(this)
      )
      .subscribe((templates) => (this.templates = templates));

    this.form
      .get('id')
      .valueChanges.pipe(
        startWith(this.templateId),
        filter((templateId) => isSomething(templateId)),
        distinctUntilChanged(),
        switchMap((templateId) =>
          this.store.pipe(select(fromRoot.selectTemplateById(templateId)))
        ),
        filter((template) => isSomething(template)),
        tap((template) => this.setFormValues(template)),
        untilComponentDestroyed(this)
      )
      .subscribe((template) => (this.template = template));
  }

  onHeadersChange(headers: PayloadHttpHeader[]) {
    this.form.patchValue({
      headers: R.map(
        (h) => ({
          name: h.name,
          value: h.value,
        }),
        headers
      ),
    });
  }

  isEditing() {
    return (
      !R.isNil(this.form.get('id').value) &&
      !R.isEmpty(this.form.get('id').value)
    );
  }

  setFormValues(template: Template) {
    this.form.setValue({
      id: template.id || '',
      name: template.name || '',
      description: template.description || '',
      type: template.type || TemplateType.Email,
      method: template.method || PayloadMethod.POST,
      headers: template.headers || [],
      subject: template.subject || '',
      languages: R.map(
        (l) => new TemplateTranslation(l),
        template.languages || []
      ),
      content: {
        body: template.body || '',
      },
    });
  }

  changeValidators(type: TemplateType) {
    let bodyControl = this.form.get('content').get('body');

    switch (type) {
      case TemplateType.PushNotification:
        bodyControl.setValidators([
          Validators.maxLength(Validations.SMSLimit),
          Validators.required,
        ]);
        break;
      default:
        bodyControl.setValidators([Validators.required]);
        break;
    }

    bodyControl.updateValueAndValidity();
  }

  onChange(form: TemplateForm) {
    switch (form.type) {
      case TemplateType.Payload:
      case TemplateType.PushNotification:
        form.subject = '';
        break;
    }

    this.change.emit(form);
    this.valid.emit(this.form.valid);
  }

  onTranslationsChange(translations: TemplateTranslation[]) {
    this.form.patchValue({languages: translations});
    this.save.emit(true);
  }
}
