import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Template} from '../../../models/template';
import {OnTemplateForm} from '../../../interfaces/template/on-template-form';
import {TemplateForm} from '../../../interfaces/template/template-form';
import {TemplatesService} from '../../../services/templates.service';
import * as R from 'ramda';
import {isEmpty, isNil} from 'ramda';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';
import {TemplateConfirmDialogResult} from '../../../interfaces/template/template-confirm-dialog-result';
import {isSomething} from '../../../utility/functions/is-something';
import {TemplateDialogTabType} from '../../../enums/template-dialog-tab-type';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {map} from 'rxjs/operators';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  Subscription,
} from 'rxjs';
import {
  ArchiveTrigger,
  DeleteTrigger,
  PauseTrigger,
  ResumeTrigger,
  UnArchiveTrigger,
} from '../../../actions/triggers.actions';
import {TriggerDialogComponent} from '../../trigger/trigger-dialog/trigger-dialog.component';
import {TemplateType} from '../../../enums/template-type';
import {TagsType} from '../../../enums/tags-type';
import {getTags} from '../../../utility/functions/get-tags';
import {Trigger} from '../../../models/trigger';
import {AmplitudeService} from '@automata/services/amplitude.service';

@Component({
  selector: 'app-template-dialog',
  templateUrl: './template-dialog.component.html',
  styleUrls: ['./template-dialog.component.scss'],
})
export class TemplateDialogComponent
  implements OnInit, OnDestroy, AfterContentChecked, OnTemplateForm {
  tableData$ = new BehaviorSubject<Trigger[]>([]);

  template: Template;
  templateForm: TemplateForm;
  isTemplateFormValid: boolean;

  tabTypes = TemplateDialogTabType;
  activeTab = new BehaviorSubject<TemplateDialogTabType>(
    TemplateDialogTabType.Template
  );

  translateControl = new EventEmitter();
  payloadControl = new EventEmitter();

  triggersSub: Subscription;

  tags: string[] = [];
  types = TemplateType;

  hasTranslations$: Observable<boolean>;

  constructor(
    private fb: FormBuilder,
    private templatesService: TemplatesService,
    private dialogService: DialogService,
    private store: Store<fromRoot.State>,
    private cd: ChangeDetectorRef,
    private amplitudeService: AmplitudeService,
    public dialogRef: MatDialogRef<TemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.template = this.data.template;

    this.tags = getTags(this.template);

    if (!this.isNew()) {
      this.triggersSub = this.store
        .select(fromRoot.selectTriggersByTemplateId(this.template.id))
        .pipe(map((triggers) => R.map((t) => new Trigger(t), triggers)))
        .subscribe((triggers) => {
          this.tableData$.next(triggers);
        });
    }
  }

  ngAfterContentChecked() {
    this.hasTranslations$ = combineLatest(
      this.activeTab,
      of(this.templateForm),
      of(this.template)
    ).pipe(
      map(([tab, form, template]) => {
        const templateType = R.path(['type'], template);
        const formType = R.path(['type'], form);
        const allowed = [TemplateType.PushNotification, TemplateType.Email];

        return (
          tab === TemplateDialogTabType.Template &&
          (R.contains(formType, allowed) || R.contains(templateType, allowed))
        );
      })
    );
  }

  ngOnDestroy() {
    if (this.triggersSub) {
      this.triggersSub.unsubscribe();
    }
  }

  isNew() {
    return isNil(this.template.id) || isEmpty(this.template.id);
  }

  addTags() {
    this.dialogService
      .openTags(this.tags, TagsType.Template)
      .subscribe((result) => {
        this.tags = result.tags;
        if (!this.isNew()) {
          this.onTemplateSave(false);
        }
      });
  }

  close() {
    this.dialogRef.close();
  }

  onTemplateChange(form: TemplateForm) {
    if (form instanceof Event) {
      return;
    }
    this.templateForm = form;
  }

  onTemplateValid(isValid: boolean) {
    setTimeout(() => (this.isTemplateFormValid = isValid), 0);
  }

  openTab(tabType: TemplateDialogTabType) {
    this.activeTab.next(tabType);
  }

  onTemplateSave(closeDialog = true) {
    if (isSomething(this.templateForm.id)) {
      this.dialogService
        .openTemplateConfirm(this.templateForm.name)
        .subscribe((result: TemplateConfirmDialogResult) => {
          this.templateForm.name = result.name;
          if (result.saveAsNew) {
            this.templateForm.id = ''; // without id it will be saved as new template
          }
          if (this.isNew()) {
            this.logNewTemplate(this.templateForm.type)
            this.amplitudeService.logEvent('templates-blank-complete')
          }
          this.templatesService.save(this.templateForm, this.tags);
          if (closeDialog) {
            this.dialogRef.close();
          }
        });
    } else {
      if (this.isNew()) {
        this.logNewTemplate(this.templateForm.type)
        this.amplitudeService.logEvent('templates-blank-complete')
      }
      this.templatesService.save(this.templateForm, this.tags);
      if (closeDialog) {
        this.dialogRef.close();
      }
    }
  }

  logNewTemplate(type: TemplateType) {
    switch (type) {
      case TemplateType.Payload:
        this.amplitudeService.logEvent('add-payload-template')
        break;
      case TemplateType.PushNotification:
        this.amplitudeService.logEvent('add-sms-template')
        break;
      case TemplateType.Email:
        this.amplitudeService.logEvent('add-email-template')
        break;
    }
  }
  confirmRemove(trigger: Trigger) {
    this.dialogService.openConfirm().subscribe(() => {
      this.onConfirmRemove(trigger);
    });
  }

  onConfirmRemove(trigger: Trigger) {
    this.store.dispatch(DeleteTrigger({id: trigger.id}));
  }

  onPause(trigger: Trigger) {
    this.store.dispatch(PauseTrigger({id: trigger.id}));
  }

  onResume(trigger: Trigger) {
    this.store.dispatch(ResumeTrigger({id: trigger.id}));
  }

  onArchive(trigger: Trigger) {
    this.store.dispatch(ArchiveTrigger({id: trigger.id}));
  }

  onUnArchive(trigger: Trigger) {
    this.store.dispatch(UnArchiveTrigger({id: trigger.id}));
  }

  onEdit(trigger: Trigger) {
    console.log(`Editing trigger ${trigger.id}`);
    this.dialogService.openSide(TriggerDialogComponent, {
      data: {triggerId: trigger.id},
    });
  }

  onDuplicate(trigger: Trigger) {
    console.log(`Editing trigger ${trigger.id}`);
    this.dialogService.openSide(TriggerDialogComponent, {
      data: {
        triggerId: trigger.id,
        isDuplicate: true,
      },
    });
  }
}
