<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header btn-holder tab-nav-holder">
      <div class="d-flex justify-content-between">
        <div class="nav-tab-holder">
          <ul class="nav nav-tabs d-flex" role="tablist">
            <li [ngClass]="{active: activeTab === tabTypes.Overview}">
              <a (click)="openTab(tabTypes.Overview)">
                {{'pageElements.overview' | translate}}
              </a>
            </li>
            <li *ngIf="!(form.get('handler').value | isSignatureHandler)"
                [ngClass]="{active: activeTab === tabTypes.Template}">
              <a (click)="openTab(tabTypes.Template)">
                {{'pageElements.templateTab' | translate}}
              </a>
            </li>
            <li *ngIf="isEditing()" [ngClass]="{active: activeTab === tabTypes.Activity}">
              <a (click)="openTab(tabTypes.Activity);">
                {{'pageElements.activity' | translate}}
              </a>
            </li>
          </ul>
        </div>
        <div [ngClass]="{'dialog-actions': isEditing()}" class="text-right">
          <mat-slide-toggle [formControl]="form.get('active')"
                            [matTooltip]="form.get('active').value | workflowTriggerStatus"
                            matTooltipPosition="below"
                            matTooltipClass="text-center"
                            [disabled]="form.invalid"
                            (change)="onStatusChange()" class="main-toggle">
            <span *ngIf="form.get('active').value">{{'pageElements.active' | translate}}</span>
            <span *ngIf="!form.get('active').value">{{'pageElements.inactive' | translate}}</span>
          </mat-slide-toggle>

          <button (click)="close()" type="button" class="btn btn-default left-icon text-uppercase">
            <i class="far fa-check-square" aria-hidden="true"></i>
            {{'buttons.cancel' | translate}}
          </button>

          <button *ngIf="form.get('rentals').value?.length && isEditing()"
                  [matTooltip]="'tooltips.addTriggerToExistingOrNewWorkflow' | translate"
                  matTooltipPosition="below"
                  matTooltipClass="text-center"
                  (click)="onAddWorkflows()" type="button"
                  class="btn btn-cstm-info left-icon text-uppercase">
            <i class="far fa-plus" aria-hidden="true"></i>
            {{'buttons.workflows' | translate}}
          </button>

          <button (click)="onAddTags()"
                  [matTooltip]="'tooltips.addTagsToTrigger' | translate"
                  matTooltipPosition="below"
                  matTooltipClass="text-center"
                  type="button" class="btn btn-cstm-info left-icon text-uppercase">
            <i class="far fa-plus" aria-hidden="true"></i>
            {{'buttons.tags' | translate}}
          </button>

          <button (click)="onConfirmRemove()" [ngClass]="{hidden: !isEditing()}" type="button"
                  class="btn btn-cst-danger left-icon text-uppercase">
            <span class="far fa-trash-alt" aria-hidden="true"></span>
            {{'buttons.delete' | translate}}
          </button>
        </div>
      </div>
      <button (click)="close()" class="cstm-close" aria-label="Close" type="button">
        <span class="far fa-times"></span>
      </button>
    </div>
    <div class="modal-body tigger-modal-body">
      <div [ngSwitch]="activeTab" class="tab-content">

        <div *ngSwitchCase="tabTypes.Overview" class="tab-pane active">
          <div class="clearfix overall-content">

            <alert *ngIf="isDuplicate" type="warning">
              <strong>{{'pageElements.warning' | translate}}
                :</strong> {{'pageElements.warningDesc1' | translate}}
              <strong>{{'buttons.save' | translate}}</strong> {{'pageElements.warningDesc2' | translate}}
            </alert>

            <div *ngIf="isEditing() && trigger" class="panel panel-default">
              <div class="clearfix header-panel">
                <div class="col-xs-12 title title-status">
                  {{'pageElements.triggerStatus' | translate}}
                </div>
              </div>
              <div class="panel-body">
                <div class="row tigger-conditions-body trigger-status-body">
                  <div class="table-holder table-responsive">
                    <table class="table table-primary">
                      <thead>
                      <tr>
                        <th width="30%">
                          {{'tableColumns.status' | translate}}:
                          <span *ngIf="trigger.status === 1"
                                class="text-success2">{{'pageElements.active' | translate}}</span>
                          <span *ngIf="trigger.status === 0"
                                class="text-danger2">{{'pageElements.inactive' | translate}}</span>
                        </th>
                        <th width="35%">
                          {{'tableColumns.created' | translate}}:
                          <span>{{trigger.createdFormattedLong}}</span>
                        </th>
                        <th width="35%">
                          {{'tableColumns.lastTriggered' | translate}}:
                          <span>{{trigger.lastUpdateFormatted}}</span>
                        </th>
                      </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <form [formGroup]="form" class="panel panel-default">
              <div class="clearfix header-panel">
                <div class="col-xs-8 title">
                  {{'pageElements.triggerDetails' | translate}}
                </div>
                <div class="col-xs-4 btn-holder">
                  <div *ngIf="form.valid">
                    <button (click)="onTriggerSave()" type="button"
                            class="btn btn-new left-icon text-uppercase">
                      <span class="far fa-save" aria-hidden="true"></span>
                      {{'buttons.save' | translate}}
                    </button>
                  </div>
                  <div *ngIf="form.invalid"
                       [matTooltip]="'tooltips.fillRequiredFields' | translate"
                       matTooltipPosition="above"
                       matTooltipClass="text-center"
                       class="di">
                    <button [disabled]="true" type="button"
                            class="btn btn-new left-icon text-uppercase">
                      <span class="far fa-save" aria-hidden="true"></span>
                      {{'buttons.save' | translate}}
                    </button>
                  </div>
                </div>
              </div>
              <div class="panel-body">
                <div class="row tigger-detail-body">
                  <div class="col-sm-6 box">
                    <div class="row box-title">
                      {{'pageElements.whenToTrigger' | translate}}
                    </div>
                    <div class="box-body">
                      <div class="clearfix input-holder">
                        <div class="col-sm-10">
                          <mat-form-field
                            [matTooltip]="'tooltips.enterNameForTrigger' | translate"
                            matTooltipPosition="below"
                            matTooltipClass="text-center">
                            <input matInput
                                   [placeholder]="'formControls.name' | translate"
                                   formControlName="name"
                                   type="text">
                            <mat-error *ngIf="helper.getName().hasError('maxlength')">
                                                            <span
                                                              [innerHtml]="'validations.nameAtMost50' | translate"></span>
                            </mat-error>
                            <mat-error *ngIf="helper.getName().hasError('pattern')">
                                                            <span
                                                              [innerHtml]="'validations.nameNoSpecial' | translate"></span>
                            </mat-error>
                            <mat-hint align="end">
                              {{helper.getName().value.length || 0}}/50
                            </mat-hint>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="clearfix input-holder">
                        <div class="col-sm-10"
                             [ngClass]="{disabledType: isEditing() && form.get('event').value | allowsTime}">
                          <app-select-search [items]="events$ | async"
                                             [ctrl]="form.get('event')"
                                             [multiple]="false"
                                             [hasBlank]="false"
                                             [matTooltip]="'tooltips.selectTriggerEvent' | translate"
                                             matTooltipPosition="below"
                                             matTooltipClass="text-center"
                                             [placeholder]="'formControls.whenTheEventOccurs' | translate"></app-select-search>
                        </div>
                      </div>
                      <div *ngIf="form.get('event').value | allowsTime"
                           class="clearfix input-holder">
                        <div formGroupName="timeevent" class="col-sm-10 time-event-box">
                          <h5 class="conditions-header">{{'pageElements.conditionsMatch' | translate}}
                            :</h5>
                          <div class="row">
                            <div class="col-sm-6">
                              <mat-form-field>
                                <input matInput
                                       [placeholder]="'formControls.days' | translate"
                                       formControlName="days"
                                       type="number"
                                       pattern="^-?(0|[1-9]\d*)?$">
                                <mat-error
                                  *ngIf="helper.getDays().touched && helper.getDays().hasError('required')">
                                                                    <span
                                                                      [innerHtml]="'validations.daysRequired' | translate"></span>
                                </mat-error>
                                <mat-error
                                  *ngIf="helper.getDays().touched && helper.getDays().hasError('minNumber')">
                                                                    <span
                                                                      [innerHtml]="'validations.daysAtLeast0' | translate"></span>
                                </mat-error>
                                <mat-error
                                  *ngIf="helper.getDays().hasError('pattern')">
                                                                    <span
                                                                      [innerHtml]="'validations.onlyWholeNumbers' | translate"></span>
                                </mat-error>
                              </mat-form-field>
                            </div>
                            <div class="col-sm-6">
                              <mat-form-field>
                                <input matInput
                                       [placeholder]="'formControls.hours' | translate"
                                       formControlName="hours"
                                       type="number"
                                       pattern="^-?(0|[1-9]\d*)?$">
                                <mat-error
                                  *ngIf="helper.getHours().hasError('required')">
                                  <span
                                    [innerHtml]="'validations.hoursRequired' | translate"></span>
                                </mat-error>
                                <mat-error
                                  *ngIf="helper.getHours().hasError('minNumber')">
                                  <span
                                    [innerHtml]="'validations.hoursAtLeast0' | translate"></span>
                                </mat-error>
                                <mat-error
                                  *ngIf="helper.getHours().hasError('maxNumber')">
                                  <span
                                    [innerHtml]="'validations.hoursAtMost24' | translate"></span>
                                </mat-error>
                                <mat-error
                                  *ngIf="helper.getHours().hasError('pattern')">
                                  <span
                                    [innerHtml]="'validations.onlyWholeNumbers' | translate"></span>
                                </mat-error>
                              </mat-form-field>
                            </div>
                            <div *ngIf="(helper.getHours().touched || helper.getDays().touched) && form.hasError('minimumTime')" class="col-sm-12">
                              <mat-error>
                                Total time must be greater than or equal to 1 hour
                              </mat-error>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12">
                              <mat-form-field>
                                <mat-select
                                  [placeholder]="'formControls.timeEventType' | translate"
                                  formControlName="event"
                                  [required]="utility.isRequired(helper.getTimeEvent())">
                                  <mat-option *ngFor="let event of timeevents"
                                              [value]="event.value">
                                    {{event.name}}
                                  </mat-option>
                                </mat-select>
                                <mat-error
                                  *ngIf="helper.getTimeEvent().touched && helper.getTimeEvent().hasError('required')">
                                                                    <span
                                                                      [innerHtml]="'validations.timeEventRequired' | translate"></span>
                                </mat-error>
                              </mat-form-field>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div *ngIf="form.get('event').value | allowsChannel"
                           class="clearfix input-holder">
                        <div class="col-sm-10"
                             [ngClass]="{disabledType: isPartOfWorkflow()}">
                          <app-select-search [items]="channels$ | async"
                                             [ctrl]="form.get('channels')"
                                             [multiple]="!isEditing() && !isPartOfWorkflow()"
                                             [hasBlank]="true"
                                             bindValue="token"
                                             bindLabel="friendlyName"
                                             [matTooltip]="'tooltips.selectTriggerChannel' | translate"
                                             matTooltipPosition="above"
                                             matTooltipClass="text-center"
                                             [placeholder]="'formControls.forThisChannel' | translate"></app-select-search>
                        </div>
                      </div>
                      <div *ngIf="form.get('event').value | allowsRental"
                           [matTooltip]="belongsToAWorkflow() ? 'This trigger is associated with a workflow. If you wish to edit the rental field, you must first remove the trigger from the workflow.' : ''"
                           matTooltipClass="text-center"
                           matTooltipPosition="above"
                           class="clearfix input-holder">
                        <div class="col-sm-10"
                             [ngClass]="{disabledType: isPartOfWorkflow() || belongsToAWorkflow()}">
                          <app-select-search *ngIf="!rentalExclusion"
                                             [items]="rentals$ | async"
                                             [ctrl]="form.get('rentals')"
                                             [multiple]="!isEditing() && !isPartOfWorkflow()"
                                             [hasBlank]="true"
                                             [matTooltip]="form.get('rentals').value?.length && isEditing() ? ('tooltips.changingRental' | translate) : ('tooltips.selectTriggerRentals' | translate)"
                                             matTooltipPosition="above"
                                             matTooltipClass="text-center"
                                             [placeholder]="'formControls.forThisRental' | translate"></app-select-search>
                          <app-select-search *ngIf="rentalExclusion"
                                             [items]="rentals$ | async"
                                             [ctrl]="form.get('not_rentals')"
                                             [multiple]="true"
                                             [hasBlank]="true"
                                             [matTooltip]="'tooltips.selectTriggerRentalSkip' | translate"
                                             matTooltipPosition="above"
                                             matTooltipClass="text-center"
                                             [placeholder]="'formControls.excludingTheseRentals' | translate"></app-select-search>
                        </div>
                        <div class="col-sm-2"
                             [ngClass]="{disabledType: isPartOfWorkflow() || belongsToAWorkflow()}">
                          <mat-slide-toggle [checked]="rentalExclusion"
                                            (change)="onRentalExclusionChange()"
                                            [matTooltip]="'tooltips.turnToExclusionList' | translate"
                                            matTooltipPosition="above"
                                            matTooltipClass="text-center"
                                            class="rentalExclusionSwitch">
                            Exclude rentals
                          </mat-slide-toggle>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 box">
                    <div class="row box-title">
                      {{'pageElements.whatToDo' | translate}}
                    </div>
                    <div class="box-body">
                      <div class="clearfix input-holder">
                        <div class="col-sm-10" [class]="(form.get('handler').value | isEmailAction) ? 'col-sm-8' : 'col-sm-10'">
                          <mat-form-field>
                            <mat-select
                              [placeholder]="'formControls.performThisAction' | translate"
                              [matTooltip]="'tooltips.selectMessageType' | translate"
                              matTooltipPosition="above"
                              matTooltipClass="text-center"
                              formControlName="handler"
                              [required]="utility.isRequired(form.get('handler'))">
                              <mat-option *ngFor="let handler of getHandlers()"
                                          [value]="handler.value">
                                                                <span *ngIf="handler.tooltip | isSomething"
                                                                      [matTooltip]="handler.value | signatureTemplateTooltip"
                                                                      matTooltipPosition="left"
                                                                      matTooltipClass="text-center"
                                                                      class="db">{{handler.name}}</span>
                                <span
                                  *ngIf="!(handler.tooltip | isSomething)">{{handler.name}}</span>
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-sm-2 email_source_settings" *ngIf="form.get('handler').value | isEmailAction">
                          <span (click)="onEditEmailFrom()"
                                matTooltip="Change the name that shows on emails"
                                matTooltipPosition="above"
                                matTooltipClass="text-center"
                                class="email_source_settings_icon">
                            <i class="fal fa-cog"></i>
                          </span>
                        </div>
                      </div>

                      <ng-container *ngIf="(form.get('handler').value | isSignatureHandler); else notSignatureHandler">
                        <div
                        class="clearfix input-holder">
                        <div  class="col-sm-10">
                            <app-select-search [items]="contracts$ | async"
                                                [ctrl]="form.get('template')"
                                                [matTooltip]="form.get('handler').value | signatureTemplateTooltip"
                                                matTooltipPosition="above"
                                                matTooltipClass="text-center"
                                                [placeholder]="'formControls.useThisContractTemplate' | translate"
                                                [multiple]="false"></app-select-search>
                                </div>
                                <div class="col-sm-10" *ngIf="signatureTemplateHasUserRole$.value">
                                  <app-select-search
                                    [items]="users$ | async"
                                    [ctrl]="form.get('recipients')"
                                    bindLabel="detailedEmailName"
                                    [matTooltip]="'tooltips.sendAsUser' | translate"
                                    matTooltipPosition="above"
                                    matTooltipClass="text-center"
                                    [placeholder]="'formControls.sendDocumentAs' | translate"></app-select-search>
                                    </div>
                          <div class="col-sm-10 checkbox-holder disabledType" *ngIf="signatureTemplateHasGuestRole$.value">
                            <mat-checkbox formControlName="guest">
                              {{'buttons.sendMessageToGuest' | translate}}
                              <span class="required-star">*</span>
                            </mat-checkbox>
                          </div>
                        </div>

                      </ng-container>

                      <ng-template #notSignatureHandler>
                        <div *ngIf="form.get('handler').value | allowsBookingTags"
                        class="clearfix input-holder">
                          <div class="col-sm-12">
                            <tag-input placeholder=""
                                        [secondaryPlaceholder]="(form.get('handler').value | isAddBookingTagHandler) ? 'Create a new tag' : 'Name a tag to remove'"
                                        [formControlName]="'bookingTags'"
                                        [addOnBlur]="true"
                                        [modelAsStrings]="true"
                                        name="bookingTags"
                                        [ripple]="false">
                              <tag-input-dropdown [keepOpen]="false"
                                                  [minimumTextLength]="0"
                                                  [limitItemsTo]="6"
                                                  [autocompleteObservable]="requestAutocomplete"></tag-input-dropdown>
                            </tag-input>
                          </div>
                        </div>
                        <div *ngIf="form.get('handler').value | allowsRecipients"
                              class="clearfix input-holder">
                          <div class="col-sm-10">
                            <app-select-search
                              [items]="users$ | async"
                              [bindLabel]="(form.get('handler').value | isSMSHandler) ? 'detailedPhoneName' : 'detailedEmailName'"
                              [ctrl]="form.get('recipients')"
                              [matTooltip]="'tooltips.triggerUser' | translate"
                              matTooltipPosition="above"
                              matTooltipClass="text-center"
                              [placeholder]="'formControls.sendToThisRecipient' | translate"></app-select-search>
                          </div>
                        </div>
                        <div *ngIf="form.get('handler').value | allowsPostUrl"
                              class="clearfix input-holder">
                          <div class="col-sm-10">
                            <mat-form-field>
                              <input matInput
                                      [placeholder]="'formControls.postToThisURL' | translate"
                                      formControlName="postUrl"
                                      [required]="utility.isRequired(form.get('postUrl'))">
                              <mat-error
                                *ngIf="form.get('postUrl').hasError('required')">
                                                              <span
                                                                [innerHtml]="'validations.urlRequired' | translate"></span>
                              </mat-error>
                              <mat-error *ngIf="form.get('postUrl').hasError('url')">
                                                              <span
                                                                [innerHtml]="'validations.urlInvalid' | translate"></span>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div
                          *ngIf="!(form.get('handler').value | isFollowUpHandler) && !(form.get('handler').value | isBookingTagHandler)"
                          class="clearfix input-holder">
                          <div class="col-sm-10">
                            <app-select-search [items]="templates"
                                                [ctrl]="form.get('template')"
                                                [matTooltip]="'tooltips.triggerTemplate' | translate"
                                                matTooltipPosition="above"
                                                matTooltipClass="text-center"
                                                [placeholder]="'formControls.useThisTemplate' | translate"
                                                [multiple]="false"></app-select-search>
                          </div>
                          <div class="col-sm-2">

                            <div *ngIf="form.get('handler').value.length === 0"
                                  [matTooltip]="'tooltips.selectActionFirst' | translate"
                                  matTooltipPosition="below"
                                  matTooltipClass="text-center">
                              <button (click)="newTemplate()" [disabled]="true"
                                      [matTooltip]="'tooltips.createNewTemplate' | translate"
                                      matTooltipPosition="above"
                                      matTooltipClass="text-center"
                                      class="new-template-btn btn btn-cstm-info text-uppercase"
                                      type="button">
                                {{'buttons.new' | translate}}
                              </button>
                            </div>

                            <div *ngIf="form.get('handler').value.length > 0">
                              <button (click)="newTemplate()"
                                      class="new-template-btn btn btn-cstm-info text-uppercase"
                                      type="button">
                                {{'buttons.new' | translate}}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="helper.hasGuestOption()"
                              class="clearfix input-holder">
                          <div class="col-sm-10 checkbox-holder">
                            <mat-checkbox formControlName="guest">
                              {{'buttons.sendMessageToGuest' | translate}}
                              <span
                                [ngClass]="{hidden: !utility.isRequired(form.get('guest'))}"
                                class="required-star">*</span>
                            </mat-checkbox>
                          </div>
                        </div>

                        <div
                          *ngIf="(form.get('handler').value | allowsDelay) && (form.get('event').value | eventAllowsDelay) && !(form.get('channels').value | isChannelAirbnb)"
                          class="clearfix input-holder">
                          <div class="col-sm-5">
                            <mat-form-field [matTooltip]="'tooltips.delaySend' | translate"
                                            matTooltipPosition="above"
                                            matTooltipClass="text-center">
                              <input matInput
                                      [placeholder]="'formControls.delaySend' | translate"
                                      formControlName="delayed" type="number"
                                      pattern="^-?(0|[1-9]\d*)?$">
                              <mat-error
                                *ngIf="form.get('delayed').hasError('maxNumber')">
                                                              <span
                                                                [innerHtml]="'validations.valueLessOrEqual72' | translate"></span>
                              </mat-error>
                              <mat-error
                                *ngIf="form.get('delayed').hasError('minNumber')">
                                                              <span
                                                                [innerHtml]="'validations.valueAtLeast0' | translate"></span>
                              </mat-error>
                              <mat-error *ngIf="form.get('delayed').hasError('pattern')">
                                                              <span
                                                                [innerHtml]="'validations.onlyWholeNumbers' | translate"></span>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div [class.hidden]="!form.valid" class="panel panel-default">
              <div class="clearfix header-panel">
                <div class="col-xs-8 title">
                  {{'pageElements.triggerConditions' | translate}}
                </div>
                <div class="col-xs-4 btn-holder">
                  <button [disabled]="!helper.canAddConditions()"
                          [matTooltip]="'tooltips.addCondition' | translate"
                          matTooltipPosition="above"
                          matTooltipClass="text-center"
                          (click)="onAddCondition()"
                          type="button" class="btn btn-cstm-info left-icon text-uppercase">
                    <span class="far fa-plus" aria-hidden="true"></span>
                    {{'buttons.new' | translate}}
                  </button>
                </div>
              </div>
              <app-conditions-table *ngIf="helper.canAddConditions()"
                                    [event]="form.get('event').value"
                                    [trigger]="trigger"
                                    [conditions]="conditions"
                                    (update)="onUpdateCondition($event)"
                                    (remove)="onRemoveCondition($event)"
              ></app-conditions-table>

              <div *ngIf="!helper.canAddConditions()" class="conditions-table-warning">
                <i class="fal fa-exclamation-triangle fa-5x"></i>
                <h5>{{'pageElements.triggerConditionsDesc' | translate}}</h5>
              </div>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="tabTypes.Template" class="tab-pane active">

          <div class="clearfix overall-content">
            <div class="panel panel-default">
              <div class="clearfix header-panel">
                <div class="col-xs-8 title">
                                    <span
                                      [ngClass]="{hidden: isNewTemplate()}">{{'pageElements.editTemplate' | translate}}</span>
                  <span
                    [ngClass]="{hidden: !isNewTemplate()}">{{'pageElements.newTemplate' | translate}}</span>
                </div>
                <div class="col-xs-4 btn-holder">

                  <button
                    [ngClass]="{hidden: !(templateForm?.type === types.PushNotification || templateForm?.type === types.Email)}"
                    (click)="translateControl.emit(true)" type="button"
                    [matTooltip]="'tooltips.generateLanguageVersions' | translate"
                    matTooltipPosition="above"
                    matTooltipClass="text-center"
                    class="btn btn-cstm-info left-icon text-uppercase mr10">
                    <span class="far fa-plus"></span> {{'buttons.translation' | translate}}
                  </button>

                  <button [ngClass]="{hidden: !(templateForm?.type === types.Payload)}"
                          (click)="payloadControl.emit(true)" type="button"
                          class="btn btn-cstm-info left-icon text-uppercase mr10">
                    <span class="far fa-plus"></span> {{'buttons.header' | translate}}
                  </button>

                  <button (click)="onTemplateSave()" [disabled]="!isTemplateFormValid"
                          type="button"
                          class="btn btn-new left-icon text-uppercase">
                    <span class="far fa-save" aria-hidden="true"></span>
                    {{'buttons.save' | translate}}
                  </button>
                </div>
              </div>
              <div class="panel-body">
                <div class="row">
                  <app-template-form [templateId]="form.get('template').value"
                                     [translateControl]="translateControl"
                                     [payloadControl]="payloadControl"
                                     [type]="getTemplateType()"
                                     (change)="onTemplateChange($event)"
                                     (valid)="onTemplateValid($event)"
                                     class="nested"></app-template-form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="tabTypes.Activity" class="tab-pane active">

          <app-trigger-logs [triggerId]="triggerId"></app-trigger-logs>
        </div>

      </div>
    </div>
  </div>
</div>
