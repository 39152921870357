import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as fromRoot from '../../../reducers/index';
import {FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Template} from '../../../models/template';
import {TriggerEvents} from '../../../models/trigger-events';
import {TriggerHandler} from '../../../enums/trigger-handler';
import {TabTypes} from '../../../enums/tab-types';
import {TriggerHandlers} from '../../../models/trigger-handlers';
import {OnTemplateForm} from '../../../interfaces/template/on-template-form';
import {TemplateForm} from '../../../interfaces/template/template-form';
import {TemplatesService} from '../../../services/templates.service';
import {Trigger} from '../../../models/trigger';
import {DialogService} from '../../../services/dialog.service';
import {validateMinNumber} from '../../../validators/min-number.validator';
import {isSomething} from '../../../utility/functions/is-something';
import * as R from 'ramda';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, share, startWith, switchMap, switchMapTo, tap} from 'rxjs/operators';
import {
  CreateCondition,
  CreateTrigger,
  CreateTriggers,
  DeleteCondition,
  DeleteTrigger,
  LoadTriggerDetails,
  SaveCondition,
  SaveTrigger
} from '../../../actions/triggers.actions';
import {TemplateType} from '../../../enums/template-type';
import {TriggerSaveRequest} from '../../../interfaces/trigger/trigger-save-request';
import {TriggersCreateRequest} from '../../../interfaces/trigger/triggers-create-request';
import {TriggerCreateRequest} from '../../../interfaces/trigger/trigger-create-request';
import {TemplateConfirmDialogResult} from '../../../interfaces/template/template-confirm-dialog-result';
import {UtilityService} from '../../../services/utility.service';
import {HandlerTemplateMap} from '../../../models/handler-template-map';
import {Workflow} from '../../../models/workflow';
import {TriggersHelperService} from '../../../services/triggers-helper.service';
import {TriggersService} from '../../../services/triggers.service';
import {validateUrl} from '../../../validators/url.validator';
import {TagsType} from '../../../enums/tags-type';
import {validateMaxNumber} from '../../../validators/max-number.validator';
import {getTags} from '../../../utility/functions/get-tags';
import {getWorkflows} from '../../../utility/functions/get-workflows';
import {hasSignatureGuestRole, hasSignatureUserRole, SignatureTemplate} from '../../../models/signature-template';
import {LoadContracts} from '../../../actions/contracts.actions';
import {getTriggerName} from '../../../utility/functions/get-trigger-name';
import {TriggerForm} from '../../../interfaces/trigger/trigger-form';
import {TriggerEvent} from '../../../interfaces/trigger/trigger-event';
import {hasGuest} from '../../../utility/functions/has-guest';
import {withoutGuest} from '../../../utility/functions/without-guest';
import {getTriggerEvent} from '../../../utility/functions/get-trigger-event';
import {ClearLastAddedSample, ClearLastAddedTemplate, ClearLastEditedTemplate} from '../../../actions/templates.actions';
import {TriggerCondition} from '@automata/features/conditions/models/trigger-condition';
import * as moment from 'moment';
import {allTags} from '@automata/selectors/tags.selectors';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {UsersService} from '@automata/services/users.service';
import {User} from '@automata/models/user';
import {AmplitudeService} from '@automata/services/amplitude.service';
import {AuthService} from '@automata/services/auth.service';
import {EmailFromSettingsDialogService} from '@automata/components/trigger/trigger-dialog/email-from-settings.dialog/email-from-settings-dialog.service';

declare const require: any;

const uuid = require('uuid/v4');

const MinimumTimeTriggerTimeValidator: ValidatorFn = (fg: FormGroup) => {
  const event = fg.get('event').value
  const days = fg.get('timeevent').get('days').value;
  const hours = fg.get('timeevent').get('hours').value;
  return days === 0 && hours === 0 && TriggerEvents.isTime(event)
    ? { minimumTime: true }
    : null;
};

@Component({
  selector:    'app-trigger-dialog',
  templateUrl: './trigger-dialog.component.html',
  styleUrls:   ['./trigger-dialog.component.scss']
})
export class TriggerDialogComponent extends OnDestroyMixin implements OnInit, OnTemplateForm {

  tabTypes = TabTypes;
  activeTab = TabTypes.Overview;
  translateControl = new EventEmitter();
  payloadControl = new EventEmitter();
  channels$ = this.store.pipe(select(fromRoot.selectChannelsWithDefaults));
  events$ = this.triggersService.getEvents(this.isPartOfWorkflow());
  users$ = this.triggersService.getUsers();
  rentals$ = this.store.pipe(select(fromRoot.selectAllRentals));
  templates$ = this.triggersService.getTemplates();

  trigger$: Observable<any>;
  contracts$ = new BehaviorSubject<Partial<SignatureTemplate>[]>([]);
  handler$: Observable<string>;
  recipients$: Observable<string>;

  types = TemplateType;
  handlers = TriggerHandlers.handlers;
  timeevents = TriggerEvents.timeevents;

  templateForm: TemplateForm;
  isTemplateFormValid: boolean;

  triggerId: string;
  trigger: Trigger;

  contracts: Partial<SignatureTemplate>[] = [];
  templates: Partial<Template>[] = [];

  isDuplicate: boolean;

  tags: string[] = [];
  allTags$: Observable<string[]>;

  workflow: Workflow;
  triggerWorkflows: string[] = [];
  rentalWorkflows: string[] = [];

  rentalExclusion = false;

  form = this.fb.group({
    id:          [''],
    name:        ['', [Validators.maxLength(50), Validators.pattern(/^([0-9a-zа-яàáâãäåąæçćèéêëęœìíïîłńðòóôõöøśùúûñüýÿżźßÞďđ\s])+$/i)]],
    event:       ['', [Validators.required]],
    timeevent:   this.fb.group({
      event: [''],
      days:  [0],
      hours: [0, [validateMinNumber(0), validateMaxNumber(24)]]
    }),
    delayed:     [0, [validateMaxNumber(72), validateMinNumber(0)]],
    channels:    [''],
    rentals:     [''],
    not_rentals: [''],
    handler:     ['', [Validators.required]],
    postUrl:     ['', []],
    recipients:  ['', [Validators.required]],
    template:    ['', [Validators.required]],
    active:      [true],
    guest:       [false, [Validators.required]],
    bookingTags: [[]],
    bookingTag:  [''],
    automata_email_from_source:  [''],
  }, {validators: MinimumTimeTriggerTimeValidator});

  conditions: TriggerCondition[] = [];

  signatureTemplateHasUserRole$ = new BehaviorSubject<boolean>(false);
  signatureTemplateHasGuestRole$ = new BehaviorSubject<boolean>(false);

  isTrial = false

  constructor(private fb: FormBuilder,
              private usersService: UsersService,
              private templatesService: TemplatesService,
              public dialogRef: MatDialogRef<TriggerDialogComponent>,
              private dialogService: DialogService,
              public utility: UtilityService,
              private triggersService: TriggersService,
              private store: Store<fromRoot.State>,
              private emailFromSettingsDialogService: EmailFromSettingsDialogService,
              public helper: TriggersHelperService,
              private auth: AuthService,
              private amplitudeService: AmplitudeService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    super()
    this.helper.initForm(this.form);
  }

  ngOnInit() {

    this.auth.status().subscribe(status => {
      this.isTrial = status.status === 'trial'
    })

    this.store.dispatch(ClearLastAddedSample());
    this.store.dispatch(ClearLastAddedTemplate());
    this.store.dispatch(ClearLastEditedTemplate());

    // this.form.valueChanges.subscribe(() => {
    //     this.utility.getFormValidationErrors(this.form);
    //     console.log('-----------------------------');
    // });

    this.triggerId = this.data.triggerId;
    this.isDuplicate = this.data.isDuplicate;
    this.activeTab = this.data.activeTab || this.activeTab;
    this.workflow = this.data.workflow;

    this.setWorkflowValues(this.workflow);

    this.store.pipe(
      select(fromRoot.selectSearchableContracts)
      )
      .subscribe(contracts => {
        this.contracts = <Partial<SignatureTemplate>[]>contracts;
        this.contracts$.next(<Partial<SignatureTemplate>[]>contracts);
      });

    this.triggersService.getLastAddedTemplate()
      .pipe(
        untilComponentDestroyed(this)
      )
      .subscribe(template => {
        if (!this.isEditing()) {
          this.form.patchValue({template: template.id});
        }
      });

    this.triggersService.getLastEditedTemplate()
      .pipe(
        untilComponentDestroyed(this)
      )
      .subscribe(template => {
        this.form.patchValue({template: template.id});
        if (this.form.valid) {
          this.onTriggerSave();
        }
      });

    this.handler$ = this.form
      .get('handler')
      .valueChanges
      .pipe(
        startWith(this.form.get('handler').value),
        tap(() => {
          this.form.patchValue({
            guest:       '',
            recipients:  '',
            template:    '',
            bookingTags: '',
            bookingTag:  '',
            postUrl:     ''
          });
        }),
        tap(() => this.loadSignatureTemplates()),
        tap(handler => this.filterSignatureTemplates(handler)),
        tap(() => {
          this.resetWhatToDoValidators();

          if (this.helper.isPostHandler()) {
            this.form.get('postUrl').setValidators([Validators.required, validateUrl()]);
          } else {
            this.form.get('postUrl').setValidators([]);
          }

          if (this.helper.isFollowUpHandler() || this.helper.isBookingTagHandler()) {
            this.form.get('template').setValidators([]);
          } else {
            this.form.get('template').setValidators([Validators.required]);
          }

          if (this.helper.isBookingTagHandler()) {
            this.form.get('bookingTags').setValidators([Validators.required]);
            this.form.get('bookingTag').setValidators([Validators.required]);
          } else {
            this.form.get('bookingTags').setValidators([]);
            this.form.get('bookingTag').setValidators([]);
          }

          this.updateGuestAndRecipientsValidators();

          this.form.get('postUrl').updateValueAndValidity();
          this.form.get('template').updateValueAndValidity();
          this.form.get('bookingTags').updateValueAndValidity();
          this.form.get('bookingTag').updateValueAndValidity();
        }),
        share()
      );

    this.form
      .get('event')
      .valueChanges
      .pipe(
        startWith(this.form.get('event').value),
        distinctUntilChanged(),
        untilComponentDestroyed(this)
      )
      .subscribe(() => {
        this.updateTimeEventValidators();
        this.updateGuestAndRecipientsValidators();
        this.updateChannelsAndRentals();
      });

    this.handler$
      .pipe(
        switchMapTo(this.triggersService.getLastAddedTemplate()),
        map((lastAdded: Template) => {
          const type = this.getTemplateTypeFromHandler(this.form.get('handler').value);
          return lastAdded.type === type;
        }),
        filter((sameType) => sameType),
        debounceTime(10),
        switchMapTo(this.triggersService.getLastAddedTemplate()),
        tap(lastAdded => {
          if (!this.isEditing()) {
            this.form.patchValue({template: lastAdded.id});
          }
        }),
        untilComponentDestroyed(this)
      )
      .subscribe(() => {
        this.store.dispatch(ClearLastAddedTemplate());
        if (this.isEditing() && this.form.valid) {
          this.onTriggerSave();
        }
      });

      combineLatest([
        this.form.get('handler').valueChanges,
        this.form.get('template').valueChanges.pipe(switchMap((id) => this.store.pipe(select(fromRoot.selectContractById, {id})))),
      ]).pipe(
        untilComponentDestroyed(this)
      ).subscribe(([handler, template]) => {
        if (this.helper.isSignatureHandler() && template) {
          this.signatureTemplateHasUserRole$.next(hasSignatureUserRole(template.roles));
          this.signatureTemplateHasGuestRole$.next(hasSignatureGuestRole(template.roles));
        } else {
          this.signatureTemplateHasUserRole$.next(false);
          this.signatureTemplateHasGuestRole$.next(false);
        }
        this.updateGuestAndRecipientsValidators();
      });

      this.signatureTemplateHasGuestRole$.pipe(untilComponentDestroyed(this)).subscribe((value) => {
        this.form.patchValue({guest: value});
      });

    this.recipients$ = this.form
      .get('recipients')
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        tap(() => this.updateGuestAndRecipientsValidators())
      );

    this.form
      .get('guest')
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        untilComponentDestroyed(this)
      )
      .subscribe(() => this.updateGuestAndRecipientsValidators());

    this.form
      .get('bookingTags')
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        untilComponentDestroyed(this)
      )
      .subscribe(() => this.updateBookingTagsValidators());

    this.form
      .get('bookingTag')
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        untilComponentDestroyed(this)
      )
      .subscribe(() => this.updateBookingTagsValidators());

    combineLatest(this.handler$, this.templates$)
      .pipe(
        map(([handler, templates]) => {
          const type = this.getTemplateTypeFromHandler(handler);
          if (type) {
            return R.filter(R.propEq('type', type), templates);
          }
          return templates;
        }),
        untilComponentDestroyed(this)
      )
      .subscribe(templates => this.templates = templates);

    this.triggersService.notifyOnMissingPhone(this.handler$, this.recipients$, this.users$)
      .pipe(
        untilComponentDestroyed(this)
      )
      .subscribe();

    this.form.get('rentals')
      .valueChanges
      .pipe(
        startWith(this.form.get('rentals').value),
        filter(() => this.isEditing()),
        switchMap(rentalId => this.store.pipe(select(fromRoot.rentalWorkflows(rentalId)))),
        untilComponentDestroyed(this)
      ).subscribe(workflows => {
      this.rentalWorkflows = workflows;
    });

    this.trigger$ = of(this.triggerId)
      .pipe(
        filter(id => isSomething(id)),
        switchMap(id => this.store.pipe(select(fromRoot.selectTriggerById(id)))),
        filter(trigger => isSomething(trigger)),
        distinctUntilChanged(),
        tap(trigger => this.loadTriggerDetails(trigger)),
        filter(trigger => trigger.hasDetails),
        switchMap(trigger => this.usersService.all().pipe(map(users => ({trigger, users})))),
        tap(({trigger, users}) => this.setFormValues(trigger, users)),
        map(({trigger, users}) => trigger),
        tap(trigger => this.trigger = trigger),
        tap(trigger => this.tags = getTags(trigger)),
        tap(trigger => this.triggerWorkflows = getWorkflows(trigger)),
        tap(trigger => this.conditions = this.trigger.conditions),
        map(trigger => trigger.settings.rental),
        switchMap(rentalId => this.store.pipe(
          select(fromRoot.rentalWorkflows(rentalId)),
          tap(workflows => {
            this.rentalWorkflows = workflows;
          }))
        )
      );

    this.allTags$ = combineLatest(
      this.store.pipe(select(allTags(TagsType.Trigger, true))),
      this.store.pipe(select(fromRoot.selectTriggerById(this.triggerId)))
    )
      .pipe(
        map(([tags, trigger]) => {
          const bookingTags = R.pathOr([], ['attributes', 'bookingTags'], trigger);
          return R.uniq(R.concat(tags, bookingTags));
        })
      );

    //region Subscriptions
    this.handler$.pipe(untilComponentDestroyed(this)).subscribe();
    this.recipients$.pipe(untilComponentDestroyed(this)).subscribe();

    /**
     * make sure this stays on the last place
     * If it's on the first place then form initiates after the trigger
     * we want form initiating before the trigger
     * then after trigger updates the values
     * the form valueChanges reflect that
     */
    this.trigger$.pipe(untilComponentDestroyed(this)).subscribe();
    //endregion

    // When editing we want to show all errors AF-882
    if (this.isEditing()) {
      setTimeout(() => {
        this.form.markAllAsTouched();
      }, 250);
    }
  }

  public requestAutocomplete = (text: string): Observable<string[]> => {
    return this.allTags$;
  };

  onEditEmailFrom() {
    this.emailFromSettingsDialogService.openSettings(this.form.get('automata_email_from_source').value).subscribe(name => {
      this.form.patchValue({automata_email_from_source: name})
    })
  }

  onAddCondition() {
    const event = this.form.get('event').value;
    this.dialogService.openTriggerCondition(event, null, this.trigger, this.trigger.conditions)
      .subscribe(({condition}) => {
        const processedCondition = new TriggerCondition({...condition, key: uuid(), updated: moment.utc().unix()});
        if (this.isEditing()) {
          const triggerId = this.trigger.id;
          this.store.dispatch(CreateCondition({request: {triggerId, condition: processedCondition}}));
        } else {
          this.conditions = R.append(processedCondition, this.conditions);
        }
      });
  }

  onUpdateCondition(params: {conditionId?: string, condition?: TriggerCondition} = {}) {
    const processedCondition = new TriggerCondition({...params.condition, key: uuid(), updated: moment.utc().unix()});
    if (this.isEditing()) {
      const triggerId = this.trigger.id;
      this.store.dispatch(SaveCondition({
        request: {
          triggerId,
          conditionId: params.conditionId,
          condition:   processedCondition
        }
      }));
    } else {
      const index = R.findIndex(R.propEq('key', params.conditionId), this.conditions);
      this.conditions = R.update(index, processedCondition, this.conditions);
    }
  }

  onRemoveCondition(conditionId: string) {
    if (this.isEditing()) {
      const triggerId = this.trigger.id;
      this.store.dispatch(DeleteCondition({
        request: {
          triggerId,
          conditionId
        }
      }));
    } else {
      this.conditions = R.reject((c: TriggerCondition) => c.key === conditionId, this.conditions);
    }
  }

  loadSignatureTemplates() {
    if (this.helper.isSignatureHandler()) {
      this.store.dispatch(LoadContracts());
    }
  }

  filterSignatureTemplates(handler: string) {
    let contracts = [];
    switch (handler) {
      case TriggerHandler.SendContract:
        contracts = R.filter((c: SignatureTemplate) => c.type === 'contract', this.contracts);
        break;
      case TriggerHandler.SendForm:
        contracts = R.filter((c: SignatureTemplate) => c.type === 'form', this.contracts);
        break;
      case TriggerHandler.SendDocument:
        contracts = R.filter((c: SignatureTemplate) => c.type === 'document', this.contracts);
        break;
      default:
        contracts = <any>this.contracts;
        break;
    }
    this.contracts$.next(contracts);
  }

  setWorkflowValues(workflow: Workflow) {
    if (!this.isPartOfWorkflow()) {
      return;
    }
    this.form.patchValue({
      rentals: workflow.rentalId
    });
    this.triggerWorkflows = R.map(R.compose(R.toLower, R.join('_'), R.split(' '), R.trim))([this.workflow.name]);
  }

  getTemplateTypeFromHandler(handler: string) {
    return HandlerTemplateMap.templateType[handler];
  }

  onRentalExclusionChange() {
    this.rentalExclusion = !this.rentalExclusion;
  }

  updateBookingTagsValidators() {
    const tags = this.form.get('bookingTags');
    const tag = this.form.get('bookingTag');

    if (this.helper.isBookingTagHandler()) {
      if (!R.isEmpty(tags.value) || !R.isEmpty(tag.value)) {
        tags.setValidators([]);
        tag.setValidators([]);
      } else {
        tags.setValidators([Validators.required]);
        tag.setValidators([Validators.required]);
      }
    } else {
      tags.setValidators([]);
      tag.setValidators([]);
    }
    tags.updateValueAndValidity();
    tag.updateValueAndValidity();
  }

  updateGuestAndRecipientsValidators() {
    const guestControl = this.form.get('guest');
    const recipientsControl = this.form.get('recipients');

    if (this.helper.hasNoGuestAndRecipientsOption()) {
      guestControl.setValidators([]);
      recipientsControl.setValidators([]);
    } else if (this.helper.hasNoGuestOption()) {
      guestControl.setValidators([]);
      recipientsControl.setValidators([Validators.required]);
    } else {
      if (guestControl.value || !R.isEmpty(recipientsControl.value)) {
        guestControl.setValidators([]);
        recipientsControl.setValidators([]);
      } else {
        guestControl.setValidators([Validators.required]);
        recipientsControl.setValidators([Validators.required]);
      }
    }

    if (this.helper.isSignatureHandler()) {
      if (this.signatureTemplateHasUserRole$.value) {
        recipientsControl.setValidators([Validators.required]);
      } else {
        recipientsControl.setValidators([]);
      }
      if (this.signatureTemplateHasGuestRole$.value) {
        guestControl.setValidators([Validators.required]);
      } else {
        guestControl.setValidators([]);
      }
    }

    guestControl.updateValueAndValidity();
    recipientsControl.updateValueAndValidity();
  }

  updateTimeEventValidators() {
    const timeEvent = this.form.get('timeevent');
    if (this.helper.isTime()) {
      timeEvent.get('event').setValidators([Validators.required]);
      timeEvent.get('days').setValidators([
        Validators.required,
        validateMinNumber(0)
      ]);
      timeEvent.get('hours').setValidators([
        Validators.required,
        validateMinNumber(0),
        validateMaxNumber(24)
      ]);
    } else {
      timeEvent.get('event').setValidators([]);
      timeEvent.get('days').setValidators([]);
      timeEvent.get('hours').setValidators([]);
    }
    timeEvent.updateValueAndValidity();
  }

  loadTriggerDetails(trigger: Trigger) {
    if (!trigger.hasDetails && !trigger.pendingDetails) {
      this.store.dispatch(LoadTriggerDetails({trigger}));
    }
  }

  getHandlers(): TriggerEvent[] {
    if (TriggerEvents.isInvoice(this.form.get('event').value) ||
      TriggerEvents.isTimeInvoice(this.form.get('event').value) ||
      TriggerEvents.isInquiry(this.form.get('event').value)
    ) {
      return this.filterHandlers(TriggerHandlers.handlers);
    } else {
      const handlers = R.filter((h: TriggerEvent) => h.value !== TriggerHandler.AddTagHandler && h.value !== TriggerHandler.RemoveTagHandler, TriggerHandlers.handlers)
      return this.filterHandlers(handlers);
    }
  }

  filterHandlers(handlers: TriggerEvent[]) {
    if (this.isTrial) {
      return R.reject(h => [
        TriggerHandler.SendContract,
        TriggerHandler.SendDocument,
        TriggerHandler.SendForm,
        TriggerHandler.SendSMSMessage
      ].includes(h.value as TriggerHandler), handlers)
    } else {
      return handlers
    }
  }

  resetWhatToDoValidators() {
    this.form.get('recipients').setValidators([Validators.required]);
    this.form.get('recipients').updateValueAndValidity();
    this.form.get('guest').setValidators([Validators.required]);
    this.form.get('guest').updateValueAndValidity();
    this.form.get('bookingTags').setValidators([]);
    this.form.get('bookingTags').updateValueAndValidity();
    this.form.get('bookingTag').setValidators([]);
    this.form.get('bookingTag').updateValueAndValidity();
    this.form.get('postUrl').setValidators([]);
    this.form.get('postUrl').updateValueAndValidity();
  }

  updateChannelsAndRentals() {
    if (!this.helper.allowsChannel()) {
      this.form.patchValue({channels: ''});
    }
    if (!this.helper.allowsRental() && !this.isPartOfWorkflow()) {
      this.form.patchValue({rentals: ''});
    }
  }

  setFormValues(trigger: Trigger, users: User[]) {

    this.rentalExclusion = isSomething(trigger.settings.not_rentals);

    let recipients = withoutGuest(trigger.settings.users)

    recipients = R.filter((userId: string) => {
      const existingUserIds = R.map((u: User) => u.id, users)
      return existingUserIds.includes(userId)
    }, recipients)

    this.form.setValue((<any>{
      id:          trigger.id,
      name:        getTriggerName(trigger),
      event:       getTriggerEvent(trigger),
      timeevent:   {
        event: trigger.timeevent || trigger.event,
        days:  R.defaultTo(0, trigger.settings.days),
        hours: R.defaultTo(0, trigger.settings.hours)
      },
      delayed:     R.defaultTo(0, trigger.attributes.delayed),
      not_rentals: R.defaultTo([], trigger.settings.not_rentals),
      channels:    this.isDuplicate ? [trigger.settings.channel] : trigger.settings.channel,
      rentals:     this.isDuplicate ? [trigger.settings.rental] : trigger.settings.rental,
      handler:     trigger.handler,
      postUrl:     R.head(trigger.settings.users) || '',
      recipients,
      template:    trigger.template,
      active:      trigger.status === 1,
      guest:       hasGuest(trigger.settings.users),
      bookingTags: trigger.attributes.bookingTags,
      bookingTag:  '',
      automata_email_from_source:  trigger.attributes.automata_email_from_source || '',
    }));
  }

  onTriggerSave() {

    const form = this.form.getRawValue() as TriggerForm;

    form.recipients = this.helper.addGuest(form);
    form.recipients = this.helper.addPostUrl(form);

    let bookingTags = form.bookingTags;
    if (!R.isEmpty(form.bookingTag.trim())) {
      bookingTags = R.append(form.bookingTag.trim(), bookingTags);
    }

    if (isSomething(this.triggerId) && !this.isDuplicate) {

      const saveRequest: TriggerSaveRequest = {
        id:          form.id,
        pkey:        form.id,
        event:       form.event,
        timeevent:   this.helper.isTime() ? form.timeevent.event : form.event,
        days:        form.timeevent.days,
        hours:       form.timeevent.hours,
        status:      form.active ? 1 : 0,
        channel:     form.channels as string,
        rental:      (this.rentalExclusion ? null : form.rentals) as string,
        not_rentals: this.rentalExclusion ? form.not_rentals : null,
        handler:     form.handler,
        service:     'automata',
        attributes:  {
          name:        form.name,
          tags:        this.tags,
          delayed:     form.delayed,
          workflows:   this.triggerWorkflows,
          bookingTags: bookingTags,
          automata_email_from_source: form.automata_email_from_source,
        },
        users:       R.isEmpty(form.recipients) ? [] : form.recipients,
        template:    form.template
      } as TriggerSaveRequest;

      this.store.dispatch(SaveTrigger({request: saveRequest}));
    } else {

      // if both are empty means there is no need for batch create
      if ((this.rentalExclusion && R.isEmpty(form.channels)) || (R.isEmpty(form.channels) && R.isEmpty(form.rentals))) {
        const createRequest: TriggerCreateRequest = {
          event:       form.event,
          timeevent:   form.timeevent.event,
          days:        form.timeevent.days,
          hours:       form.timeevent.hours,
          status:      form.active ? 1 : 0,
          handler:     form.handler,
          service:     'automata',
          conditions:  this.conditions,
          attributes:  {
            name:        form.name,
            tags:        this.tags,
            delayed:     form.delayed,
            workflows:   this.triggerWorkflows,
            bookingTags: bookingTags,
            automata_email_from_source: form.automata_email_from_source,
          },
          not_rentals: this.rentalExclusion ? form.not_rentals : null,
          users:       R.isEmpty(form.recipients) ? [] : form.recipients,
          template:    form.template
        } as TriggerCreateRequest;

        this.store.dispatch(CreateTrigger({request: createRequest}));
        if (!this.isPartOfWorkflow()) {
          this.amplitudeService.logEvent('trigger-blank-complete')
        }
        this.close();
      } else {
        const channels = R.isEmpty(form.channels) ? [''] : R.is(Array, form.channels) ? form.channels : [form.channels];
        const rentals = R.isEmpty(form.rentals) ? [''] : R.is(Array, form.rentals) ? form.rentals : [form.rentals];
        const createRequest: TriggersCreateRequest = {
          event:       form.event,
          timeevent:   form.timeevent.event,
          days:        form.timeevent.days,
          hours:       form.timeevent.hours,
          status:      form.active ? 1 : 0,
          channels, // in order for batch create to work, default to array with single empty string
          rentals, // in order for batch create to work, default to array with single empty string
          not_rentals: this.rentalExclusion ? form.not_rentals : null,
          handler:     form.handler,
          service:     'automata',
          conditions:  this.conditions,
          attributes:  {
            name:        form.name,
            tags:        this.tags,
            delayed:     form.delayed,
            workflows:   this.triggerWorkflows,
            bookingTags: bookingTags,
            automata_email_from_source: form.automata_email_from_source,
          },
          users:       R.isEmpty(form.recipients) ? [] : form.recipients,
          template:    form.template
        } as TriggersCreateRequest;

        this.store.dispatch(CreateTriggers({request: createRequest}));
        if (!this.isPartOfWorkflow()) {
          this.amplitudeService.logEvent('trigger-blank-complete')
        }
        this.close();
      }
    }
  }

  isPartOfWorkflow() {
    return !R.isNil(this.workflow);
  }

  belongsToAWorkflow() {
    return this.trigger?.attributes?.workflows?.length > 0;
  }

  isEditing() {
    return isSomething(this.triggerId) && !this.isDuplicate;
  }

  isNewTemplate() {
    return R.isNil(this.form.get('template').value) || R.isEmpty(this.form.get('template').value);
  }

  newTemplate() {
    this.form.patchValue({template: ''});
    this.openTab(TabTypes.Template);
  }

  openTab(tabType: TabTypes) {
    this.activeTab = tabType;
  }

  getTemplateType() {
    return this.getTemplateTypeFromHandler(this.form.get('handler').value) || TemplateType.Email;
  }

  onStatusChange() {
    if (isSomething(this.triggerId) && !this.isDuplicate && this.form.valid) {
      if (this.form.get('active').value) {
        this.triggersService.resume(this.triggerId)
          .subscribe(trigger => {
            if (trigger) {
              this.onTriggerSave();
            }
          });
      } else {
        this.triggersService.pause(this.triggerId)
          .subscribe(trigger => {
            if (trigger) {
              this.onTriggerSave();
            }
          });
      }
    }
  }

  onAddTags() {
    this.dialogService.openTags(this.tags, TagsType.Trigger)
      .subscribe((result) => {
        this.tags = result.tags;
        if (this.isEditing()) {
          this.onTriggerSave();
        }
      });
  }

  onAddWorkflows() {
    this.dialogService.openWorkflows(this.triggerWorkflows, this.rentalWorkflows)
      .subscribe(result => {
        if (this.triggersService.areWorkflowsChanged(this.triggerWorkflows, result.workflows)) {
          this.triggerWorkflows = result.workflows;
          this.onTriggerSave();
        }
      });
  }

  onTemplateChange(form: TemplateForm) {
    if (form instanceof Event) {
      return;
    }
    setTimeout(() => {
      this.templateForm = form;
    });
  }

  onTemplateValid(isValid: boolean) {
    setTimeout(() => this.isTemplateFormValid = isValid, 0);
  }

  onTemplateSave() {
    if (isSomething(this.templateForm.id)) {
      this.dialogService.openTemplateConfirm(this.templateForm.name)
        .subscribe((result: TemplateConfirmDialogResult) => {
          this.templateForm.name = result.name;
          if (result.saveAsNew) {
            this.templateForm.id = ''; // without id it will be saved as new template
          }
          this.openTab(TabTypes.Overview);
          this.templatesService.save(this.templateForm);

        });
    } else {
      this.openTab(TabTypes.Overview);
      this.templatesService.save(this.templateForm);
    }
  }

  onConfirmRemove() {
    this.dialogService.openConfirm().subscribe(() => {
      this.store.dispatch(DeleteTrigger({id: this.trigger.id}));
      this.close();
    });
  }

  close() {
    this.dialogRef.close();
  }
}
