import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as R from 'ramda';
import {catchError, map} from 'rxjs/operators';
import {AutomataNotification} from '../models/automata-notification';
import {RequestLimit} from '../enums/request-limit';
import {Observable, of} from 'rxjs';
import {TriggerNotificationTableModel} from '../models/trigger-notification-table-model';
import {isSomething} from '../utility/functions/is-something';
import {TriggerEvents} from '../models/trigger-events';
import {ActivityEventFilter} from '../interfaces/activity-event-filter';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';
import {ToastIconClasses} from '@automata/utility/models/toast-icon-classes';

declare const require: any;

const uuid = require('uuid/v4');

@Injectable()
export class ActivitiesService {

  constructor(private http: HttpClient,
              private toastr: ToastrService) {
  }

  get(limit = RequestLimit.Activities, offset = 0) {
    return this.http.get<object[]>(`@api/notification/automata/?limit=${limit}&skip=${offset}`)
      .pipe(
        map(response => R.filter((r: any) => r.service !== 'signature', response)),
        map(response => R.map(r => {
          let notification = AutomataNotification.deserialize(r);
          if (notification.object === 'DBObjects::Inquiry') {
            notification.inquiryId = notification.id;
          }
          notification.id = uuid();
          notification.sentChartFormatted = moment.unix(notification.sent).format('D MMM YYYY');
          return notification;
        }, response)),
        catchError(() => {
          this.toastr.show('Error loading notifications.', 'Error', {toastClass: ToastIconClasses.Error});
          return of([]);
        })
      );
  }

  sortedFilters(notifications$: Observable<TriggerNotificationTableModel[]>): Observable<ActivityEventFilter[]> {
    return notifications$
      .pipe(
        map(activities => {
          let filters = TriggerEvents.allSortedEvents() as ActivityEventFilter[];
          const groups = R.groupBy(a => a.event, <TriggerNotificationTableModel[]>activities);
          filters = R.map((filter) => (<any>{
            ...filter,
            status: isSomething(groups[filter.value]) ? groups[filter.value].length : null
          }), filters);
          filters = R.sort((a, b) => b.status - a.status, <ActivityEventFilter[]>filters);
          filters = R.prepend({name: 'All', value: '', i18n: 'triggerEvents.all'}, <ActivityEventFilter[]>filters);
          return filters;
        })
      );
  }
}
