import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Dictionary } from 'ramda';
import { Observable } from 'rxjs';
import * as lodash from 'lodash';

@Injectable()
export class InquiriesService {
  constructor(private http: HttpClient) { }
  getCostAfterFormulaBatch(ids: string[]): Observable<Dictionary<number>> {
    const url = `@api/inquiry/formula`
    return this.http.post<{ [key: string]: { cost: number; formula_id: string } }>(url, ids).pipe(
      map((res) => {
        return lodash.reduce(
          res,
          (acc, value, key) => {
            if (!value.formula_id) {
              return acc
            }

            acc[key] = value.cost
            return acc
          },
          {} as Dictionary<number>
        )
      })
    )
  }

  getCostAfterFormula(inquiryId: string): Observable<number> {
    return this.getCostAfterFormulaBatch([inquiryId]).pipe(map((res) => res[inquiryId]))
  }
}
