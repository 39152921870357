import {Pipe, PipeTransform} from '@angular/core';
import {TriggerHandler} from '@automata/enums/trigger-handler';

@Pipe({
  name: 'isEmailAction'
})
export class IsEmailActionPipe implements PipeTransform {

  transform(handler: string): boolean {
    return handler === TriggerHandler.SendEmailMessage;
  }

}
