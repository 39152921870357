import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AccountDetailsResponse} from '../interfaces/account/account-details-response';
import {flatMap, map, tap, toArray} from 'rxjs/operators';
import {AccountDetails} from '../models/account-details';
import {from, Observable} from 'rxjs';
import {AccountDetailsForm} from '../interfaces/account/account-details-form';
import {AccountDetailsSaveRequest} from '../interfaces/account/account-details-save-request';
import * as R from 'ramda';
import {ApiKeyResponse} from '../interfaces/account/api-key-response';
import {ApiKey} from '../models/api-key';
import {ApiKeyDeleteResponse} from '../interfaces/account/api-key-delete-response';
import {ApiKeyDelete} from '../interfaces/account/api-key-delete';
import {AuthService} from './auth.service';
import {AccountApiKeys} from '../interfaces/account/account-api-keys';
import {ChangePasswordRequest} from '../interfaces/change-password-request';
import {ToastService} from '@automata/services/toast.service';
import {getCountryName} from '@automata/models/country-list';

@Injectable()
export class AccountService {

  constructor(private http: HttpClient,
              private toast: ToastService,
              private auth: AuthService) {
  }

  get(): Observable<AccountDetails> {
    return this.http.get<AccountDetailsResponse>(`@api/account/trusted/detail`)
      .pipe(
        map(response => AccountDetails.deserialize(response))
      );
  }

  changePassword(accountPkey: string, request: ChangePasswordRequest) {
    return this.http.put(`@api/user/password/${accountPkey}`, request).pipe(tap(() => this.toast.success('Password changed successfully.')));
  }

  deleteApiKey(apiKey: ApiKey): Observable<ApiKeyDelete> {
    return this.http.delete<ApiKeyDeleteResponse>(`@api/account/apikey/${apiKey.key}/${apiKey.apikey}`)
      .pipe(
        map((response) => ({
          key:     response.key,
          account: this.auth.getUser().account
        } as ApiKeyDelete)),
        tap(() => this.toast.success('apiKeyDeleted'))
      );
  }

  save(account: AccountDetails, form: AccountDetailsForm): Observable<AccountDetails> {
    let params = {
      address: {
        address:      form.address,
        city:         form.city,
        country_code: form.country,
        country:      getCountryName(form.country),
        postal_code:  form.zipCode,
        state:        form.state
      },
      attributes: {
        ...account.attributes,
        automata_email_from_source: form.automata_email_from_source,
      },
      email:   form.email,
      name:    form.name,
      phone:   form.phone,
      vat_tin: form.tax
    } as AccountDetailsSaveRequest;
    return this.http.put<AccountDetailsResponse>(`@api/account/detail`, params)
      .pipe(
        map(response => AccountDetails.deserialize(response)),
        tap(() => this.toast.success('accountUpdated'))
      );
  }

  getApiKeys(): Observable<AccountApiKeys> {
    return this.http.get<ApiKeyResponse[]>(`@api/account/apikey`)
      .pipe(
        map(response => R.filter(r => !R.isNil(r.key), response)),
        flatMap(response => from(response)),
        map(response => ApiKey.deserialize(response)),
        toArray(),
        map(apiKeys => ({
          id:   this.auth.getUser().account,
          keys: apiKeys
        } as AccountApiKeys))
      );
  }

  generateAPIKey(name: string, internal = false): Promise<ApiKey> {
    return this.http.put<ApiKeyResponse>(`@api/account/apikey`, {name, internal})
      .pipe(
        map(response => ApiKey.deserialize(response))
      )
      .toPromise();
  }

  hasIFTTTApiKey(apiKeys: ApiKey[]) {
    return R.filter((key: ApiKey) => key.name.startsWith('IFTTT'), <any>apiKeys).length > 0;
  }

  skipTriggers() {
    return this.http.put(`@api/trigger/account/skip`, {});
  }

  unSkipTriggers() {
    return this.http.put(`@api/trigger/account/unskip`, {});
  }

  getTriggersSkip() {
    return this.http.get(`@api/trigger/skip/account`)
      .pipe(
        map((response: any) => response.skipped === '1')
      );
  }
}
