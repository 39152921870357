import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  DeleteApiKey,
  DeleteApiKeyComplete,
  GetSkipTriggers,
  GetSkipTriggersComplete,
  LoadAccount,
  LoadAccountComplete,
  LoadApiKeys,
  LoadApiKeysComplete,
  SaveAccount,
  SaveAccountComplete,
  SkipTriggers,
  SkipTriggersComplete,
  UnSkipTriggers,
  UnSkipTriggersComplete
} from '../actions/accounts.actions';
import {ActionFailed} from '../actions/utility.actions';
import {of} from 'rxjs';
import {AccountService} from '../services/account.service';
import {catchError, map, switchMap, switchMapTo, tap} from 'rxjs/operators';
import {ToastService} from '@automata/services/toast.service';

@Injectable()
export class AccountsEffects {

  @Effect()
  loadAccount$ = this.actions$
    .pipe(
      ofType(LoadAccount),
      switchMapTo(this.account.get()
        .pipe(
          map(account => LoadAccountComplete({account})),
          catchError(error => of(ActionFailed({error})))
        )
      )
    );

  @Effect()
  deleteApiKey$ = this.actions$
    .pipe(
      ofType(DeleteApiKey),
      switchMap(({key}) => this.account
        .deleteApiKey(key)
        .pipe(
          map(response => DeleteApiKeyComplete({key: response})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  loadApiKeys$ = this.actions$
    .pipe(
      ofType(LoadApiKeys),
      switchMapTo(
        this.account
          .getApiKeys()
          .pipe(
            map(apiKeys => LoadApiKeysComplete({keys: apiKeys})),
            catchError(error => of(ActionFailed({error})))
          )
      )
    );

  @Effect()
  saveAccount$ = this.actions$
    .pipe(
      ofType(SaveAccount),
      switchMap(({account, form}) => this.account
        .save(account, form)
        .pipe(
          map(account => SaveAccountComplete({account})),
          catchError(error => of(ActionFailed({error})))
        ))
    );

  @Effect()
  skipAccount$ = this.actions$
    .pipe(
      ofType(SkipTriggers),
      switchMapTo(
        this.account
          .skipTriggers()
          .pipe(
            tap(() => this.toast.success('triggersSkip')),
            map(() => SkipTriggersComplete()),
            catchError(error => of(ActionFailed({error})))
          )
      )
    );

  @Effect()
  getSkip$ = this.actions$
    .pipe(
      ofType(GetSkipTriggers),
      switchMapTo(
        this.account
          .getTriggersSkip()
          .pipe(
            map(isSkipped => GetSkipTriggersComplete({isSkipped})),
            catchError(error => of(ActionFailed({error})))
          )
      )
    );

  @Effect()
  unSkip$ = this.actions$
    .pipe(
      ofType(UnSkipTriggers),
      switchMapTo(
        this.account
          .unSkipTriggers()
          .pipe(
            tap(() => this.toast.success('triggersResume')),
            map(() => UnSkipTriggersComplete()),
            catchError(error => of(ActionFailed({error})))
          )
      )
    );

  constructor(private actions$: Actions,
              private toast: ToastService,
              private account: AccountService) {
  }
}
