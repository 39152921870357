<div class="main-body templates-page clearfix">
  <div class="container-fluid">
    <div class="row title-holder">
      <div class="col-xs-3 title">
        {{'pageTitle.templates' | translate}}
        <span [matTooltip]="'pageTooltip.templates' | translate"
              matTooltipPosition="above"
              matTooltipClass="text-center">
                    <i class="far info-icon fa-question-circle"></i>
                </span>
        <app-tutorial-opener [type]="'templates'"></app-tutorial-opener>
      </div>
      <div class="col-xs-6">
        <app-inactive-subscription></app-inactive-subscription>
      </div>
      <div class="col-xs-3 btn-holder">
        <button *ngIf="isAnySelected()" (click)="confirmDelete()" type="button"
                class="btn btn-cst-danger left-icon text-uppercase">
          <span class="far fa-trash-alt"></span> {{'buttons.delete' | translate}}
        </button>
        <div *ngIf="!isAnySelected()"
             [matTooltip]="'tooltips.noTemplatesSelected' | translate"
             matTooltipPosition="above"
             matTooltipClass="text-center"
             class="delete-button-wrapper">
          <button [disabled]="true" type="button" class="btn btn-cst-danger left-icon text-uppercase">
            <span class="far fa-trash-alt"></span> {{'buttons.delete' | translate}}
          </button>
        </div>
        <div dropdown class="btn-group">
          <button dropdownToggle type="button"
                  class="btn btn-cstm-info left-icon text-uppercase dropdown-toggle">
            <span class="far fa-plus"></span> {{'buttons.new' | translate}}
          </button>
          <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
            <li role="menuitem">
              <a [matTooltip]="'tooltips.createTemplateFromSample' | translate"
                 matTooltipPosition="left"
                 matTooltipClass="text-center"
                 class="dropdown-item" (click)="openSamples()">
                {{'buttons.startWithSample' | translate}}
              </a>
              <a [matTooltip]="'tooltips.createTemplateFromScratch' | translate"
                 matTooltipPosition="left"
                 matTooltipClass="text-center"
                 class="dropdown-item" (click)="openNew()">
                {{'buttons.startWithBlank' | translate}}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="content-holder clearfix">
      <!-- input fields -->
      <div class="clearfix input-holder-top">
        <div class="d-flex justify-content-between">
          <div class="nav-tab-holder">
            <ul class="nav nav-tabs d-flex" role="tablist">
              <li [ngClass]="{active: (activeType$ | async) === tabTypes.None}" role="presentation">
                <a (click)="activeType$.next(tabTypes.None)" aria-controls="all" role="tab">
                  {{'pageElements.templateTab1' | translate}}
                </a>
              </li>
              <li [ngClass]="{active: (activeType$ | async) === tabTypes.Email}" role="presentation">
                <a (click)="activeType$.next(tabTypes.Email)" aria-controls="email" role="tab">
                  {{'pageElements.templateTab2' | translate}}
                </a>
              </li>
              <li [ngClass]="{active: (activeType$ | async) === tabTypes.Payload}" role="presentation">
                <a (click)="activeType$.next(tabTypes.Payload)" aria-controls="payload" role="tab">
                  {{'pageElements.templateTab3' | translate}}
                </a>
              </li>
              <li [ngClass]="{active: (activeType$ | async) === tabTypes.PushNotification}" role="presentation">
                <a (click)="activeType$.next(tabTypes.PushNotification)" aria-controls="sms" role="tab">
                  SMS/PN
                </a>
              </li>
              <li *ngIf="hasAutoReviews" [ngClass]="{active: (activeType$ | async) === tabTypes.Review}" role="presentation">
                <a (click)="activeType$.next(tabTypes.Review)" aria-controls="review" role="tab">
                  Review
                </a>
              </li>
            </ul>
          </div>
          <div class="template-search-field search-field edit-align row">
            <div class="col-sm-6">
              <app-select-search
                [matTooltip]="'tooltips.templateTagFilter' | translate"
                matTooltipPosition="above"
                matTooltipClass="text-center"
                [items]="tags$ | async"
                [ctrl]="tagsCtrl"
                [placeholder]="'formControls.tags' | translate"></app-select-search>
            </div>
            <div class="col-sm-6">
              <mat-form-field>
                <input matInput (keyup)="applyFilter($event.target.value)"
                       [placeholder]="'formControls.searchHere' | translate"
                       type="search">
                <span matPrefix><i class="far fa-search"></i></span>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- Tab panes -->
        <div class="tab-content">
          <!-- Filter by All table -->
          <div role="tabpanel" class="tab-pane active">

            <mat-table #table matSort matSortActive="created" matSortDirection="desc"
                       [dataSource]="dataSource">

              <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                  </mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  <div *ngIf="row.triggers.length > 0 || (templateAssociatedToLegacy | call:row:legacyTriggers)"
                       [matTooltip]="'tooltips.templatesAssigned' | translate"
                       matTooltipPosition="right"
                       matTooltipClass="text-center">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="$event ? selection.toggle(row) : null"
                                  [disabled]="true"
                                  [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                  </div>
                  <div *ngIf="row.triggers.length === 0 && !(templateAssociatedToLegacy | call:row:legacyTriggers)">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="$event ? selection.toggle(row) : null"
                                  [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef
                                 mat-sort-header> {{'tableColumns.templateName' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element"
                          [matTooltip]="element.body | stripHtml | truncate:100"
                          matTooltipPosition="above"
                          matTooltipClass="text-center break-tooltip"> {{element.friendlyName}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="tagsFormatted">
                <mat-header-cell *matHeaderCellDef
                                 mat-sort-header> {{'tableColumns.tags' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.tagsFormatted | truncate:35}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="typeName">
                <mat-header-cell *matHeaderCellDef
                                 mat-sort-header> {{'tableColumns.type' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">

                  <ng-template #tolTemplate>
                    <div class="tal">
                      <strong>{{'tooltips.name' | translate}}:</strong> {{element.name}}
                      <br>
                      <strong>{{'tooltips.subject' | translate}}:</strong> {{element.subject}}
                      <br>
                      <strong>{{'tooltips.body' | translate}}
                        :</strong> {{element.body | stripHtml | truncate:100}}
                    </div>
                  </ng-template>
                  <span [tooltip]="tolTemplate">
                                    {{element.typeName}}
                                </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="created">
                <mat-header-cell *matHeaderCellDef
                                 mat-sort-header> {{'tableColumns.created' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.createdFormatted}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="lastused">
                <mat-header-cell *matHeaderCellDef
                                 mat-sort-header> {{'tableColumns.lastUsed' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.lastUsedFormatted}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="triggers">
                <mat-header-cell *matHeaderCellDef
                                 mat-sort-header> {{'tableColumns.triggers' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element" class="text-center"> {{element.triggers.length}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="edit">
                <mat-header-cell *matHeaderCellDef>
                  <app-column-edit-control (select)="tableManagement()"></app-column-edit-control>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <div dropdown container="body">
                    <div dropdownToggle (click)="$event.stopPropagation()"
                         class="text-center elipse-btn">
                      <i class="far fa-ellipsis-v-alt"></i>
                    </div>
                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                      <li role="menuitem">
                        <a class="dropdown-item"
                           (click)="openEdit(element)">{{'buttons.edit' | translate}}</a>
                      </li>
                      <li role="menuitem">
                        <button class="dropdown-item" *ngIf="element.triggers.length === 0"
                                type="button"
                                (click)="onConfirmRemove(element, legacyTriggers)">{{'buttons.delete' | translate}}
                        </button>
                        <button class="dropdown-item" *ngIf="element.triggers.length > 0"
                                type="button"
                                (click)="onRemove(element, legacyTriggers)">{{'buttons.delete' | translate}}
                        </button>
                      </li>
                    </ul>

                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openEdit(row)"></mat-row>
            </mat-table>

            <app-translated-pagination>
              <mat-paginator #paginator
                             [ngClass]="{hidden: isEmptyTable && isLoaded}"
                             [length]="dataSource?.data?.length"
                             [pageSize]="10"
                             [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
                             [showFirstLastButtons]="true">
              </mat-paginator>
            </app-translated-pagination>

            <app-empty-table *ngIf="isEmptyTable && isLoaded" [type]="tableType"></app-empty-table>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>
