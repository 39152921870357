export enum TemplateType {
  None = 0,
  Email = 1,
  Contract = 3,
  PushNotification = 5,
  Payload = 6,
  CustomBranding = 7,
  InvoiceTemplate = 8,
  Review,
}
