import {Injectable} from '@angular/core';
import * as R from 'ramda'
import * as moment from 'moment'
import {CurrencyPipe} from '@angular/common';
import { Observable, isObservable, of } from 'rxjs';
import { catchError, concatMap, map, mapTo, tap, toArray } from 'rxjs/operators';
import { InquiriesService } from '../../../services/inquiries.service';

declare const require: any

const random = require('lodash/random');
const reduce = require('lodash/reduce');
const isFunction = require('lodash/isFunction');
const isNil = require('lodash/isNil');

export interface DataDictItemDef {
  type: string
  value: string | Function
  tooltip?: string
}

export interface RentalSample {
  _id: string
  pkey: string
  account: number
  address: {
    address: string
    country_code: string
    state: string
    zip: number
    city: string
    country: string
  },
  archived: number
  attributes: {
    last_rateengine_update: number
  }
  checkin: {
    hour: number
    string: string
    timezone: string
    offset_min: number
    min: number
  }
  checkout: {
    string: string
    hour: number
    timezone: string
    offset_min: number
    min: number
  }
  color: string
  created: number
  currency: {
    code: string
    icon: string
    img: string
    symbol: string
  },
  deleted: number
  email: string
  name: string
  phone: string
  taxes: {
    amount: number
    type: string
    name: string
  }[]
  lastupdate: number
  bathrooms: number
  bedrooms: number
  description: string
  detail: any
  size: number
  size_metric: string
  sleep_max: number
  sleep_min: number
  type: string
  instructions: {
    checkout: string
    directions: string
    rules: string
    checkin: string
  },
  specifics: {
    sec_code: number
    wifi_pass: string
    key_pickup: string
    special_inst: string
  },
  payment_instructions: string
  payment_terms: string
  custom1: string
  custom2: string
  custom3: string
  custom4: string
  custom5: string
}

export interface InquirySample {
  _id: string
  check_in: string
  touch: string
  attributes: any
  check_out: string
  ref_id: string
  account: number
  rental_id: string
  quotes: string
  created: number
  guest_id: string
  inquiry_id: string
  canceled: number
  booking_engine: {
    currency: string
    taxpercent: number
    extraguests: number
    discountfees: any[]
    taxtotal: number
    taxes: {
      name: string
      rate: number
      amount: number
      type: string
    }[]
    base: number
    total: number
    ratecategory: string
    discounttotal: number
  },
  read: number
  replyto: string
  deleted: number
  received_on: number
  pkey: string
  num_child: number
  booked: number
  archived: number
  logs: {
    note: string
    timestamp: number
    key: string
    user: string
  }[],
  inquiry_source: string
  guest_arrive: number
  convo_id: string
  guest_details: {
    name: string
    email: string
  }
  closed: number
  guest_depart: number
  num_adults: number
  cost: string
}

export interface GuestSample {
  _id: string
  notes: any[]
  created: number
  primaryemail: string
  email: {
    [key: string]: number
  }
  name: string
  lastmessage: number
  phone: number
  deleted: number
  pkey: string
  phones: number[]
  source: string
  address: {
    address: string
    state: string
    country_code: string
    city: string
    postal_code: number
    country: string
  },
  account: number
  archived: number
  username: string
  password: string
  attributes: any
  drink: string
  hobby: string
  kids: string
  sport: string
  wife: string
  birthday: string
}

interface DataDictResolveParams {
  rental?: RentalSample
  inquiry?: InquirySample,
  guest?: GuestSample
}

const handleLineBreaks = (text) => (text || '').replace(/\r?\n/g, '<br />')

@Injectable()
export class DataDictService {
  interpolate = /(\*\|[\s\S]+?\|\*)/g

  constructor(private inquiriesService: InquiriesService) { }

  dictDefs: { [key: string]: DataDictItemDef } = {
    '*|CONTRACT:SIGNATURE|*': {
      type: 'contract',
      value: '[sign]',
    },
    '*|CONTRACT:COUNTERSIGN|*': {
      type: 'contract',
      value: '[countersign]',
    },
    '*|CONTRACT:DATE|*': {
      type: 'contract',
      value: '[date]',
    },

    '*|GUEST:POSTAL_CODE|*': {
      type: 'guest',
      tooltip: 'Will be pulled from the associated inquiry.',
      value: ({ guest }: DataDictResolveParams) => guest.address?.postal_code || '',
    },
    '*|GUEST:NAME|*': {
      type: 'guest',
      tooltip: 'Will be pulled from the associated inquiry.',
      value: ({ guest }: DataDictResolveParams) => guest.name || '',
    },
    '*|GUEST:FNAME|*': {
      type: 'guest',
      tooltip: `Will show only guest's first name in the message.`,
      value: ({ guest }: DataDictResolveParams) => R.head((guest.name || '').split(' ')),
    },
    '*|GUEST:EMAIL|*': {
      type: 'guest',
      tooltip: 'Will be pulled from the associated inquiry.',
      value: ({ guest }: DataDictResolveParams) => guest.primaryemail || '',
    },
    '*|GUEST:PHONE|*': {
      type: 'guest',
      tooltip: 'Will be pulled from the associated inquiry (if provided) and is stored in the guest record.',
      value: ({ guest }: DataDictResolveParams) => guest.phone || '',
    },
    '*|GUEST:ADDRESS|*': {
      type: 'guest',
      tooltip: 'This is stored in the guest record (if provided).',
      value: ({ guest }: DataDictResolveParams) => guest.address?.address || '',
    },
    '*|GUEST:CITY|*': {
      type: 'guest',
      tooltip: 'This is stored in the guest record (if provided).',
      value: ({ guest }: DataDictResolveParams) => guest.address?.city || '',
    },
    '*|GUEST:COUNTRY|*': {
      type: 'guest',
      tooltip: 'This is stored in the guest record (if provided).',
      value: ({ guest }: DataDictResolveParams) => guest.address?.country || '',
    },
    '*|GUEST:SOURCE|*': {
      type: 'guest',
      tooltip: 'Will be pulled from the associated inquiry.',
      value: ({ inquiry }: DataDictResolveParams) => inquiry.inquiry_source || '',
    },
    '*|GUEST:BIRTHDAY|*': {
      type: 'guest',
      tooltip: 'Will be pulled from the guest record which you can edit to include these details.',
      value: ({ guest }: DataDictResolveParams) => guest.birthday || '',
    },
    '*|GUEST:SPOUSE|*': {
      type: 'guest',
      tooltip: 'Will be pulled from the guest record which you can edit to include these details.',
      value: ({ guest }: DataDictResolveParams) => guest.wife || '',
    },
    '*|GUEST:HOBBY|*': {
      type: 'guest',
      tooltip: 'Will be pulled from the guest record which you can edit to include these details.',
      value: ({ guest }: DataDictResolveParams) => guest.hobby || '',
    },
    '*|GUEST:RAND4|*': {
      type: 'guest',
      tooltip: 'Include a random 4 digit code which can be used to program smart locks.',
      value: () => random(1000, 9999),
    },
    '*|GUEST:RAND6|*': {
      type: 'guest',
      tooltip: 'Include a random 6 digit code which can be used to program smart locks.',
      value: () => random(100000, 999999),
    },

    // Inquiry data

    '*|INQUIRY:INQUIRY_ID|*': {
      type: 'inquiry',
      tooltip: 'This is imported from the listing channel and can be found in the notes section of the inquiry.',
      value: ({ inquiry }: DataDictResolveParams) => inquiry.pkey,
    },
    '*|INQUIRY:CHANNEL|*': {
      type: 'inquiry',
      tooltip: 'The channel where we imported the associated inquiry from.',
      value: ({ inquiry }: DataDictResolveParams) => inquiry.inquiry_source || null,
    },
    '*|INQUIRY:ARRIVE|*': {
      type: 'inquiry',
      tooltip: 'The date that the guest is checking in.',
      value: ({ inquiry }: DataDictResolveParams) => moment.utc(inquiry.guest_arrive * 1000).format('ddd MMM DD, YYYY'),
    },
    '*|INQUIRY:DEPART|*': {
      type: 'inquiry',
      tooltip: 'The date the guest is due to check-out.',
      value: ({ inquiry }: DataDictResolveParams) => moment.utc(inquiry.guest_depart * 1000).format('ddd MMM DD, YYYY'),
    },
    '*|INQUIRY:CHECK_IN|*': {
      type: 'inquiry',
      tooltip: 'The check-in time set for this rental under your rental Instructions tab in the rental details.',
      value: ({ inquiry, rental }: DataDictResolveParams) => {
        return moment().format('H:mm')
      },
    },
    '*|INQUIRY:CHECK_OUT|*': {
      type: 'inquiry',
      tooltip: 'The check-out time set for this rental under your rental instructions tab in the rental details.',
      value: ({ inquiry, rental }: DataDictResolveParams) => {
        return moment().format('H:mm')
      },
    },
    '*|INQUIRY:COST|*': {
      type: 'inquiry',
      tooltip: 'The booking total which is shown on the associated inquiry.',
      value: ({ inquiry, rental }: DataDictResolveParams) => {
        const code = R.pathOr('USD', ['currency', 'code'], rental)
        return new CurrencyPipe('en').transform(inquiry.booking_engine.total, code, 'symbol')
      },
    },
    '*|INQUIRY:BOOKING_FORMULA|*': {
      type: 'inquiry',
      tooltip: 'Booking costs were adjusted via the booking formula set on the channel.',
      value: ({ inquiry, rental }: DataDictResolveParams) => {
        const code = R.pathOr('USD', ['currency', 'code'], rental)
        return this.inquiriesService
          .getCostAfterFormula(inquiry.pkey)
          .pipe(map((total) => new CurrencyPipe('en').transform(total, code, 'symbol')))
      },
    },
    '*|INQUIRY:NIGHTS|*': {
      type: 'inquiry',
      tooltip: 'The number of nights the guest is staying.',
      value: ({ inquiry }: DataDictResolveParams) => 5,
    },
    '*|INQUIRY:ADULTS|*': {
      type: 'inquiry',
      tooltip: 'The number of adults which is stored in the inquiry.',
      value: ({ inquiry }: DataDictResolveParams) => inquiry.num_adults || 0,
    },
    '*|INQUIRY:CHILDREN|*': {
      type: 'inquiry',
      tooltip: 'The number of Children set in the inquiry details.',
      value: ({ inquiry }: DataDictResolveParams) => inquiry.num_child || 0,
    },
    '*|INQUIRY:GUEST_PORTAL|*': {
      type: 'inquiry',
      tooltip: 'The number of Children set in the inquiry details.',
      value: ({ inquiry }: DataDictResolveParams) => 'https://www.tokeet.com',
    },
    '*|INQUIRY:BOOK_DATE|*': {
      type: 'inquiry',
      tooltip: 'This is the date the booking was imported by Tokeet.',
      value: ({ inquiry }: DataDictResolveParams) => {
        return inquiry.booked ? moment.utc(inquiry.booked * 1000).format('ddd MMM DD, YYYY') : '';
      },
    },

    // Rental data

    '*|RENTAL:WEBSITES|*': {
      type: 'rental',
      tooltip: 'The Tokeet rental name.',
      value: ({ rental }: DataDictResolveParams) => 'https://www.tokeet.com',
    },

    '*|RENTAL:POSTAL_CODE|*': {
      type: 'rental',
      tooltip: 'The Tokeet rental name.',
      value: ({ rental }: DataDictResolveParams) => rental.address?.zip || '',
    },
    '*|RENTAL:NAME|*': {
      type: 'rental',
      tooltip: 'The Tokeet rental name.',
      value: ({ rental }: DataDictResolveParams) => rental.name || '',
    },
    '*|RENTAL:PHONE|*': {
      type: 'rental',
      tooltip: 'The Tokeet rental phone number. Pulled from the Basic Info section of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => rental.phone || '',
    },
    '*|RENTAL:EMAIL|*': {
      type: 'rental',
      tooltip: 'The Tokeet rental email. Pulled from the Basic Info section of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => rental.email || '',
    },
    '*|RENTAL:ADDRESS|*': {
      type: 'rental',
      tooltip: 'The Tokeet rental address. Pulled from the Basic Info section of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.address && rental.address.address) || '',
    },
    '*|RENTAL:CITY|*': {
      type: 'rental',
      tooltip: 'The Tokeet rental city. Pulled from the Basic Info section of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.address && rental.address.city) || '',
    },
    '*|RENTAL:STATE|*': {
      type: 'rental',
      tooltip: 'The Tokeet rental state. Pulled from the Basic Info section of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.address && rental.address.state) || '',
    },
    '*|RENTAL:COUNTRY|*': {
      type: 'rental',
      tooltip: 'The Tokeet rental country. Pulled from the Basic Info section of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.address && rental.address.country) || '',
    },
    '*|RENTAL:DESCRIPTION|*': {
      type: 'rental',
      tooltip: 'Pulled from the description field on the Detail Information tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => rental.description || '',
    },
    '*|RENTAL:TERMS|*': {
      type: 'rental',
      tooltip: '',
      value: ({ rental }: DataDictResolveParams) => rental.payment_terms || '',
    },
    '*|RENTAL:CUSTOM1|*': {
      type: 'rental',
      tooltip: 'Pulled from the Custom Information 1 field on the Custom Information tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => handleLineBreaks(rental.custom1),
    },
    '*|RENTAL:CUSTOM2|*': {
      type: 'rental',
      tooltip: 'Pulled from the Custom Information 2 field on the Custom Information tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => handleLineBreaks(rental.custom2),
    },
    '*|RENTAL:CUSTOM3|*': {
      type: 'rental',
      tooltip: 'Pulled from the Custom Information 3 field on the Custom Information tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => handleLineBreaks(rental.custom3),
    },
    '*|RENTAL:CUSTOM4|*': {
      type: 'rental',
      tooltip: 'Pulled from the Custom Information 4 field on the Custom Information tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => handleLineBreaks(rental.custom4),
    },
    '*|RENTAL:CUSTOM5|*': {
      type: 'rental',
      tooltip: 'Pulled from the Custom Information 5 field on the Custom Information tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => handleLineBreaks(rental.custom5),
    },
    '*|RENTAL:CHECKIN_INSTRUCTIONS|*': {
      type: 'rental',
      tooltip: 'Pulled from the Check-in Instructions field on the Rental Instructions tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.instructions && rental.instructions.checkin) || '',
    },
    '*|RENTAL:CHECKOUT_INSTRUCTIONS|*': {
      type: 'rental',
      tooltip: 'Pulled from the Checkout Instructions field on the Rental Instructions tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.instructions && rental.instructions.checkout) || '',
    },
    '*|RENTAL:HOUSE_RULES|*': {
      type: 'rental',
      tooltip: 'Pulled from the House Rules field on the Rental Instructions tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.instructions && rental.instructions.rules) || '',
    },
    '*|RENTAL:DIRECTIONS|*': {
      type: 'rental',
      tooltip: 'Pulled from the Directions field on the Rental Instructions tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.instructions && rental.instructions.directions) || '',
    },
    '*|RENTAL:INSTRUCTIONS|*': {
      type: 'rental',
      value: ({ rental }: DataDictResolveParams) => (rental.payment_instructions || '').replace(/\n/gi, '<br/>'),
    }, // back compatible
    '*|RENTAL:PAYMENT_INSTRUCTIONS|*': {
      type: 'rental',
      value: ({ rental }: DataDictResolveParams) => (rental.payment_instructions || '').replace(/\n/gi, '<br/>'),
    },
    '*|RENTAL:WIFI|*': {
      type: 'rental',
      tooltip: 'Pulled from the WiFi password field on the Rental Instructions tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.specifics && rental.specifics.wifi_pass) || '',
    },
    '*|RENTAL:KEYPICKUP|*': {
      type: 'rental',
      tooltip: 'Pulled from the Key Pickup field on the Rental Instructions tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.specifics && rental.specifics.key_pickup) || '',
    },
    '*|RENTAL:SECURITY|*': {
      type: 'rental',
      tooltip: 'Pulled from the Codes and Passwords section on your Rental Instructions tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.specifics && rental.specifics.sec_code) || '',
    },
    '*|RENTAL:SPECIAL|*': {
      type: 'rental',
      tooltip: 'Pulled from the Special Instructions field on Rental Instructions tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.specifics && rental.specifics.special_inst) || '',
    },
    '*|DISCOUNT:5|*': {
      type: 'discount',
      value: () => 'ABC12',
    },
    '*|DISCOUNT:10|*': {
      type: 'discount',
      value: () => 'ABC12',
    },
    '*|DISCOUNT:15|*': {
      type: 'discount',
      value: () => 'ABC12',
    },
    '*|DISCOUNT:20|*': {
      type: 'discount',
      value: () => 'ABC12',
    },
  }

  getAllDataTokensInText(text): string[] {
    let m
    const tokens = []
    do {
      m = this.interpolate.exec(text)
      if (m) {
        tokens.push(m[1])
      }
    } while (m)

    return tokens
  }

  resolveTokens(tokens: string[], params: DataDictResolveParams): Observable<{ [key: string]: any }> {
    const dataTokenResolver = this.resolver(params)
    const tokenValuePairs = {}
    return of(...tokens).pipe(
      concatMap((token) => {
        const resolver = dataTokenResolver(token)
        const ob = isObservable(resolver) ? resolver : of(resolver)
        return ob.pipe(
          catchError(() => of('')),
          tap((v) => (tokenValuePairs[token] = v))
        )
      }),
      toArray(),
      mapTo(tokenValuePairs)
    )
  }

  resolve(text, params: DataDictResolveParams): Observable<string> {
    text = text || ''
    if (!text) return of('')

    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
    }

    const tokens = this.getAllDataTokensInText(text)

    return this.resolveTokens(tokens, params).pipe(
      map((pairs) => {
        return reduce(
          tokens,
          (t, token) => {
            return t.replace(new RegExp(escapeRegExp(token), 'g'), isNil(pairs[token]) ? '' : pairs[token])
          },
          text
        )
      })
    )
  }

  private resolver(params: DataDictResolveParams) {
    return (key) => {
      const item = this.dictDefs[key] as any
      if (item) {
        if (isFunction(item.value)) {
          return item.value(params)
        }
        return item.value
      }
    }
  }
}
