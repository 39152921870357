<div class="row">
  <div class="col-sm-12">
    <div class="drop-container" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)"
         [uploadInput]="uploadInput">
      <div class="content-wrapper">
        {{'pageElements.dragAFile' | translate}}
        <label class="upload-button">
          <input type="file" ngFileSelect [options]="options"
                 (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple>
          {{'pageElements.browse' | translate}}
        </label>
        {{'pageElements.toUpload' | translate}}
      </div>
      <div *ngIf="data?.description" class="content-description">
        {{data.description | translate}}
      </div>
    </div>
  </div>
  <div class="col-sm-12">
    <div class="upload-items">
      <div class="upload-item" *ngFor="let f of files; let i = index;">
                              <span class="filename">
                                <span>{{ f.name }} ({{ f.id }}) - {{ f.progress?.data?.percentage }}%</span>
                                <span>uploading with {{ f.progress?.data?.speedHuman }}</span>
                                <span>ETA {{ f.progress?.data?.etaHuman }}</span>
                              </span>
        <div class="progress">
          <span class="bar" [style.width]="f?.progress?.data?.percentage + '%'"></span>
        </div>
      </div>
    </div>
  </div>

</div>
