import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {EmptyTableComponent} from '@automata/components/empty-table/empty-table.component';
import {ColumnEditControlComponent} from '@automata/components/tables/column-edit-control/column-edit-control.component';
import {TranslateModule} from '@ngx-translate/core';
import {StripHtmlPipe} from '@automata/pipes/strip-html.pipe';
import {TruncatePipe} from '@automata/pipes/truncate.pipe';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {SelectSearchComponent} from '@automata/components/select-search/select-search.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {TutorialOpenerComponent} from '@automata/components/tutorial/tutorial-opener.component';
import {LayoutModule} from '@automata/layout/layout.module';
import {InactiveSubscriptionComponent} from '@automata/components/inactive/inactive-subscription.component';
import {AutomataToastComponent} from '@automata/components/toast/toast.component';
import {TemplateEditorComponent} from '@automata/components/template/template-editor/template-editor.component';
import {AttachmentDialogComponent} from '@automata/components/template/attachment-dialog/attachment-dialog.component';
import {DictionaryDialogComponent} from '@automata/components/template/dictionary-dialog/dictionary-dialog.component';
import {TemplateFormComponent} from '@automata/components/template/template-form/template-form.component';
import {TriggerDialogComponent} from '@automata/components/trigger/trigger-dialog/trigger-dialog.component';
import {TemplateDialogComponent} from '@automata/components/template/template-dialog/template-dialog.component';
import {ConfirmDialogComponent} from '@automata/components/confirm-dialog/confirm-dialog.component';
import {TriggerLogsComponent} from '@automata/components/trigger/trigger-logs/trigger-logs.component';
import {WorkflowDialogComponent} from '@automata/components/workflow/workflow-dialog/workflow-dialog.component';
import {WorkflowDetailTriggerComponent} from '@automata/components/workflow/workflow-detail-trigger/workflow-detail-trigger.component';
import {TemplateConfirmDialogComponent} from '@automata/components/template/template-confirm-dialog/template-confirm-dialog.component';
import {ConditionDialogComponent} from '@automata/features/conditions/components/condition-dialog/condition-dialog.component';
import {ConditionValueComponent} from '@automata/features/conditions/components/condition-value/condition-value.component';
import {ConditionsTableComponent} from '@automata/features/conditions/components/conditions-table/conditions-table.component';
import {IntercomWidgetComponent} from '@automata/components/intercom/intercom-widget/intercom-widget.component';
import {BookingHasWorkflowPipe} from '@automata/pipes/booking-has-workflow.pipe';
import {AllowsDelayPipe} from '@automata/pipes/trigger-events/allows-delay.pipe';
import {EventAllowsDelayPipe} from '@automata/pipes/trigger-events/event-allows-delay.pipe';
import {LanguageNamePipe} from '@automata/pipes/language-name.pipe';
import {EventColorPipe} from '@automata/pipes/event-color.pipe';
import {EventNamePipe} from '@automata/pipes/event-name.pipe';
import {TimeAgoPipe} from '@automata/pipes/time-ago.pipe';
import {IsNilPipe} from '@automata/pipes/is-nil.pipe';
import {NotificationBoxClassPipe} from '@automata/pipes/notification-box-class.pipe';
import {NotificationGuestImagePipe} from '@automata/pipes/notification-guest-image.pipe';
import {WordCountPipe} from '@automata/pipes/word-count.pipe';
import {IsTriggerSkippablePipe} from '@automata/pipes/is-trigger-skippable.pipe';
import {SignatureTemplateTooltipPipe} from '@automata/pipes/signature-template-tooltip.pipe';
import {IsSkippedForInquiryPipe} from '@automata/pipes/is-skipped-for-inquiry.pipe';
import {IsSomethingPipe} from '@automata/pipes/is-something.pipe';
import {SafeHtmlPipe} from '@automata/pipes/safe-html.pipe';
import {SkippedInquiryTooltipTextPipe} from '@automata/pipes/skipped-inquiry-tooltip-text.pipe';
import {ListOfTriggersPipe} from '@automata/pipes/list-of-triggers.pipe';
import {EpochPipe} from '@automata/pipes/epoch.pipe';
import {ListOfStringsPipe} from '@automata/pipes/list-of-strings.pipe';
import {WorkflowStatusTooltipPipe} from '@automata/pipes/workflow-status-tooltip.pipe';
import {WorkflowTriggerStatusPipe} from '@automata/pipes/workflow-trigger-status.pipe';
import {WorkflowDetailGlobalTooltipPipe} from '@automata/pipes/workflow-detail-global-tooltip.pipe';
import {BookingDetailGlobalTooltipPipe} from '@automata/pipes/booking-detail-global-tooltip.pipe';
import {AllowsRentalPipe} from '@automata/pipes/trigger-events/allows-rental.pipe';
import {AllowsChannelPipe} from '@automata/pipes/trigger-events/allows-channel.pipe';
import {AllowsTimePipe} from '@automata/pipes/trigger-events/allows-time.pipe';
import {isFormOrDocumentPipe} from '@automata/pipes/trigger-events/is-form-or-document.pipe';
import {AllowsBookingTagsPipe} from '@automata/pipes/trigger-events/allows-booking-tags.pipe';
import {AsyncSchedulePipe} from '@automata/pipes/async-schedule.pipe';
import {AllowsRecipientsPipe} from '@automata/pipes/trigger-events/allows-recipients.pipe';
import {AllowsPostUrlPipe} from '@automata/pipes/trigger-events/allows-post-url.pipe';
import {AllowsTemplatesPipe} from '@automata/pipes/trigger-events/allows-templates.pipe';
import {IsFollowUpHandlerPipe} from '@automata/pipes/trigger-events/is-follow-up-handler.pipe';
import {IsBookingTagHandlerPipe} from '@automata/pipes/trigger-events/is-booking-tag-handler.pipe';
import {IsAddBookingTagHandlerPipe} from '@automata/pipes/trigger-events/is-add-booking-tag-handler.pipe';
import {IsRemoveBookingTagHandlerPipe} from '@automata/pipes/trigger-events/is-remove-booking-tag-handler.pipe';
import {IsSignatureHandlerPipe} from '@automata/pipes/trigger-events/is-signature-handler.pipe';
import {TriggerNamePipe} from '@automata/pipes/trigger-name.pipe';
import {FieldOrPathPipe} from '@automata/pipes/field-or-path.pipe';
import {IsChannelAirbnbPipe} from '@automata/pipes/is-channel-airbnb.pipe';
import {IsInquiryPipe} from '@automata/pipes/trigger-events/is-inquiry.pipe';
import {JoinArrayPipe} from '@automata/pipes/join-array.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTabsModule} from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {MatSortModule} from '@angular/material/sort';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {NgxUploaderModule} from 'ngx-uploader';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatBadgeModule} from '@angular/material/badge';
import {MatListModule} from '@angular/material/list';
import {MatStepperModule} from '@angular/material/stepper';
import {AceEditorModule} from 'ng2-ace-editor';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {ClipboardModule} from 'ngx-clipboard';
import {TagInputModule} from 'ngx-chips';
import {NgxDnDModule} from '@swimlane/ngx-dnd';
import {NgSelectModule} from '@ng-select/ng-select';
import {TriggerTableComponent} from '@automata/components/trigger/trigger-table/trigger-table.component';
import {CreateApiKeyDialogComponent} from '@automata/components/account/create-api-key-dialog/create-api-key-dialog.component';
import {IftttIntegrationDialogComponent} from '@automata/components/account/ifttt-integration-dialog/ifttt-integration-dialog.component';
import {IntegrationInfoDialogComponent} from '@automata/components/integrations/integration-info-dialog/integration-info-dialog.component';
import {HttpHeadersTableComponent} from '@automata/components/template/http-headers-table/http-headers-table.component';
import {PayloadHeadersDialogComponent} from '@automata/components/template/payload-headers-dialog/payload-headers-dialog.component';
import {TemplateTranslationsTableComponent} from '@automata/components/template/template-translations-table/template-translations-table.component';
import {TemplateTranslateDialogComponent} from '@automata/components/template/template-translate-dialog/template-translate-dialog.component';
import {ActivityListItemComponent} from '@automata/components/activity/activity-list/activity-list-item/activity-list-item.component';
import {ActivityTableControlsComponent} from '@automata/components/activity/activity-table-controls/activity-table-controls.component';
import {TagsDialogComponent} from '@automata/components/tags/tags-dialog/tags-dialog.component';
import {BookingsTriggerTableComponent} from '@automata/components/bookings/bookings-trigger-table/bookings-trigger-table.component';
import {TableManagementDialogComponent} from '@automata/components/tables/table-management-dialog/table-management-dialog.component';
import {CodeDialogComponent} from '@automata/components/codes/code-dialog/code-dialog.component';
import {EditorComponent} from '@automata/components/editor/editor/editor.component';
import {BookingDialogComponent} from '@automata/components/bookings/booking-dialog/booking-dialog.component';
import {TemplateSamplesDialogComponent} from '@automata/components/template/template-samples-dialog/template-samples-dialog.component';
import {TemplateSampleComponent} from '@automata/components/template/template-sample/template-sample.component';
import {TriggerWorkflowsDialogComponent} from '@automata/components/trigger/trigger-workflows-dialog/trigger-workflows-dialog.component';
import {TriggerTooltipComponent} from '@automata/components/tables/trigger-tooltip/trigger-tooltip.component';
import {WorkflowWizardComponent} from '@automata/components/workflow/workflow-wizard/workflow-wizard.component';
import {WorkflowDuplicateDialogComponent} from '@automata/components/workflow/workflow-duplicate-dialog/workflow-duplicate-dialog.component';
import {WorkflowDeleteConfirmDialogComponent} from '@automata/components/workflow/workflow-delete-confirm-dialog/workflow-delete-confirm-dialog.component';
import {NewWorkflowDialogComponent} from '@automata/components/workflow/new-workflow-dialog/new-workflow-dialog.component';
import {BookingJourneyComponent} from '@automata/components/bookings/booking-journey/booking-journey.component';
import {ChangePasswordDialogComponent} from '@automata/components/account/change-password-dialog/change-password-dialog.component';
import {TriggerSamplesDialogComponent} from '@automata/components/trigger/trigger-samples-dialog/trigger-samples-dialog.component';
import {BookingMessagesDialogComponent} from '@automata/components/bookings/booking-messages-dialog/booking-messages-dialog.component';
import {TutorialDialogComponent} from '@automata/components/tutorial/tutorial-dialog.component';
import {BillingComponent} from '@automata/containers/billing/billing.component';
import {WorkflowRenameDialogComponent} from '@automata/components/workflow/workflow-rename-dialog/workflow-rename-dialog.component';
import {ExistingTriggersDialogComponent} from '@automata/components/workflow/existing-triggers-dialog/existing-triggers-dialog.component';
import {SlackInfoDialogComponent} from '@automata/components/integrations/slack-info-dialog/slack-info-dialog.component';
import {AlertDialogComponent} from '@automata/components/alert-dialog/alert-dialog.component';
import {GroupViewComponent} from '@automata/components/workflow/group-view/group-view.component';
import {TranslatedPaginationComponent} from '@automata/utility/components/translated-pagination/translated-pagination.component';
import {EcoFabSpeedDialModule} from '@ecodev/fab-speed-dial';
import {CodeTableComponent} from '@automata/components/codes/code-table/code-table.component';
import {AllowsConditionsPipe} from '@automata/pipes/trigger-events/allows-conditions.pipe';
import {ActivityListComponent} from '@automata/components/activity/activity-list/activity-list.component';
import {TriggerUnsupportedPipe} from '@automata/pipes/trigger-unsupported.pipe';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {AlertModule} from 'ngx-bootstrap/alert';
import {RemoveTemplateDialogComponent} from '@automata/containers/templates/remove-template/remove-template-dialog.component';
import {RemoveTemplateDialogService} from '@automata/containers/templates/remove-template/remove-template-dialog.service';
import {PreviewTemplateDialogService} from '@automata/components/template/preview-template/preview-template-dialog.service';
import {PreviewTemplateDialogComponent} from '@automata/components/template/preview-template/preview-template-dialog.component';
import {IsSMSHandlerPipe} from '@automata/pipes/trigger-events/is-sms-handler.pipe';
import {SendSampleService} from '@automata/components/template/preview-template/send-sample/send-sample.service';
import {SendSampleDialogService} from '@automata/components/template/preview-template/send-sample/send-sample-dialog.service';
import {SendSampleDialogComponent} from '@automata/components/template/preview-template/send-sample/send-sample-dialog.component';
import {IsEmailActionPipe} from '@automata/pipes/trigger-events/is-email-action.pipe';
import {EmailFromSettingsDialogComponent} from '@automata/components/trigger/trigger-dialog/email-from-settings.dialog/email-from-settings-dialog.component';
import {EmailFromSettingsDialogService} from '@automata/components/trigger/trigger-dialog/email-from-settings.dialog/email-from-settings-dialog.service';


const modules = [
  CommonModule,
  MatPaginatorModule,
  MatTableModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatFormFieldModule,
  MatInputModule,
  TranslateModule,
  LayoutModule,
  FormsModule,
  ReactiveFormsModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTabsModule,
  MatMenuModule,
  MatDividerModule,
  MatSortModule,
  TooltipModule,
  BsDropdownModule,
  NgxMatSelectSearchModule,
  NgxUploaderModule,
  MatDialogModule,
  MatButtonModule,
  MatIconModule,
  MatButtonToggleModule,
  MatBadgeModule,
  MatListModule,
  MatDividerModule,
  MatStepperModule,
  AceEditorModule,
  NgxChartsModule,
  ClipboardModule,
  TagInputModule,
  NgxDnDModule,
  NgSelectModule,
  ModalModule,
  EcoFabSpeedDialModule
];

const pipes = [
  StripHtmlPipe,
  TruncatePipe,
  BookingHasWorkflowPipe,
  AllowsDelayPipe,
  EventAllowsDelayPipe,
  TruncatePipe,
  LanguageNamePipe,
  EventColorPipe,
  EventNamePipe,
  TimeAgoPipe,
  IsNilPipe,
  NotificationBoxClassPipe,
  NotificationGuestImagePipe,
  WordCountPipe,
  StripHtmlPipe,
  TriggerUnsupportedPipe,
  IsTriggerSkippablePipe,
  SignatureTemplateTooltipPipe,
  IsSkippedForInquiryPipe,
  IsSomethingPipe,
  SafeHtmlPipe,
  SkippedInquiryTooltipTextPipe,
  ListOfTriggersPipe,
  EpochPipe,
  ListOfStringsPipe,
  WorkflowStatusTooltipPipe,
  WorkflowTriggerStatusPipe,
  WorkflowDetailGlobalTooltipPipe,
  BookingDetailGlobalTooltipPipe,
  AllowsRentalPipe,
  AllowsChannelPipe,
  AllowsConditionsPipe,
  AllowsTimePipe,
  isFormOrDocumentPipe,
  AllowsBookingTagsPipe,
  AsyncSchedulePipe,
  AllowsRecipientsPipe,
  AllowsPostUrlPipe,
  AllowsTemplatesPipe,
  IsFollowUpHandlerPipe,
  IsBookingTagHandlerPipe,
  IsEmailActionPipe,
  IsAddBookingTagHandlerPipe,
  IsRemoveBookingTagHandlerPipe,
  IsSignatureHandlerPipe,
  IsSMSHandlerPipe,
  TriggerNamePipe,
  FieldOrPathPipe,
  IsChannelAirbnbPipe,
  IsInquiryPipe,
  JoinArrayPipe
];

export const components = [
  ActivityListComponent,
  EmptyTableComponent,
  ColumnEditControlComponent,
  SelectSearchComponent,
  TutorialOpenerComponent,
  InactiveSubscriptionComponent,
  AutomataToastComponent,
  TemplateEditorComponent,
  AttachmentDialogComponent,
  DictionaryDialogComponent,
  TemplateFormComponent,
  TriggerDialogComponent,
  EmailFromSettingsDialogComponent,
  TemplateDialogComponent,
  ConfirmDialogComponent,
  TriggerLogsComponent,
  WorkflowDialogComponent,
  WorkflowDetailTriggerComponent,
  EmptyTableComponent,
  TemplateConfirmDialogComponent,
  ConditionDialogComponent,
  ConditionValueComponent,
  ConditionsTableComponent,
  IntercomWidgetComponent,
  TriggerTableComponent,
  CreateApiKeyDialogComponent,
  IftttIntegrationDialogComponent,
  IntegrationInfoDialogComponent,
  HttpHeadersTableComponent,
  PayloadHeadersDialogComponent,
  TemplateTranslationsTableComponent,
  TemplateTranslateDialogComponent,
  ActivityListItemComponent,
  ActivityTableControlsComponent,
  TagsDialogComponent,
  BookingsTriggerTableComponent,
  TableManagementDialogComponent,
  CodeDialogComponent,
  EditorComponent,
  BookingDialogComponent,
  TemplateSamplesDialogComponent,
  TemplateSampleComponent,
  TriggerWorkflowsDialogComponent,
  TriggerTooltipComponent,
  WorkflowWizardComponent,
  WorkflowDuplicateDialogComponent,
  WorkflowDeleteConfirmDialogComponent,
  NewWorkflowDialogComponent,
  BookingJourneyComponent,
  ChangePasswordDialogComponent,
  TriggerSamplesDialogComponent,
  BookingMessagesDialogComponent,
  TutorialDialogComponent,
  BillingComponent,
  WorkflowRenameDialogComponent,
  ExistingTriggersDialogComponent,
  SlackInfoDialogComponent,
  AlertDialogComponent,
  GroupViewComponent,
  TranslatedPaginationComponent,
  CodeTableComponent,
  SendSampleDialogComponent
];

export const dialogs = [
  RemoveTemplateDialogComponent,
  PreviewTemplateDialogComponent,
];

export const directives = [];

export const services = [
  RemoveTemplateDialogService,
  PreviewTemplateDialogService,
  EmailFromSettingsDialogService,
  SendSampleService,
  SendSampleDialogService,
];

export const declarations = [
  ...pipes,
  ...dialogs,
  ...components,
  ...directives
];

@NgModule({
  imports:         [
    ...modules,
    AlertModule.forRoot()
  ],
  declarations: [
    declarations
  ],
  providers:       [
    ...services
  ],
  exports:         [
    ...declarations,
    ...modules,
    AlertModule
  ],
  entryComponents: [
    ...dialogs
  ]
})
export class SharedModule {
}
